import axios from "axios";
import { GET_CUSTOMER_COMPLETION_COUNT, GET_CUSTOMER_PROJECT_TRACKING_DROPDOWN, GET_CUSTOMER_REMARK, GET_CUSTOMER_SUBASS, GET_VENDOR_DASHBORD_COUNT_API, GET_VENDOR_PROFILE_COUNT_API, SUBMIT_CUSTOMER_REMARK } from "../Api";

export function GetProfileCompleteCount(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        GET_VENDOR_PROFILE_COUNT_API,
        postData,
    );
}

export function GetVendorDashboardCount(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        GET_VENDOR_DASHBORD_COUNT_API,
        postData,
    );
}

// -------

export function GetCustomerDashboardCount(id) {
    const postData = {
        customer_auto_id:id,
    };
    return axios.post(
        GET_CUSTOMER_COMPLETION_COUNT,
        postData,
    );
}

//______________

export function GetCustomerProjectDropdown(id) {
    const postData = {
        customer_id:id,
    };
    return axios.post(
        GET_CUSTOMER_PROJECT_TRACKING_DROPDOWN,
        postData,
    );
}

export function setCustomerRemark(postData) {
  
    return axios.post(
        SUBMIT_CUSTOMER_REMARK,
        postData,
    );
}
export function getCustomerRemark(postData) {
  
    return axios.post(
        GET_CUSTOMER_REMARK,
        postData,
    );
}





export function GetCustomerSubass(id,pno) {
    const postData = {
        customer_id:id,
        po_no:pno
    };
    return axios.post(
        GET_CUSTOMER_SUBASS,
        postData,
    );
}