import axios from "axios";
import { GET_SEGMENT_API, UPDATE_VENDOR, UPDATE_VENDOR_BANK_DETAIL, UPDATE_VENDOR_FININCIAL_DETAIL, VENDOR_BANK_DETAIL_API, VENDOR_FINICIAL_DETAIL_API, VENDOR_PROFILE_API } from "../Api";

export function GetVendorBankDetails(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        VENDOR_BANK_DETAIL_API,
        postData,
    );
}
export function GetVendorFinicialDetails(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        VENDOR_FINICIAL_DETAIL_API,
        postData,
    );
}

export function GetVendorProfile(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        VENDOR_PROFILE_API,
        postData,
    );
}


///-------------------------------

export function UpdateVendorProfile(data) {
    // const postData = {
    //     vendor_auto_id:id,
    // };
    return axios.post(
        UPDATE_VENDOR,
        data,
    );
}



export function UpdateVendorFinincialProfile(data) {
    // const postData = {
    //     vendor_auto_id:id,
    // };
    return axios.post(
        UPDATE_VENDOR_FININCIAL_DETAIL,
        data,
    );
}

export function UpdateVendorBankProfile(data) {
    // const postData = {
    //     vendor_auto_id:id,
    // };
    return axios.post(
        UPDATE_VENDOR_BANK_DETAIL,
        data,
    );
}

export function getSegmentApi(data) {
    // const postData = {
    //     vendor_auto_id:id,
    // };
    return axios.post(
        GET_SEGMENT_API,
       
    );
}