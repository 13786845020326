import React, { useState, forwardRef, useImperativeHandle, useEffect  } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Button, Dropdown, Offcanvas, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import { GetMainCategoryList } from '../../../services/RFQ/Rfq';


const RegrettedRfqCanvas=forwardRef((props, ref) => {
    const [addEmploye , setAddEmploye] = useState(false);
    const [mainCategoryList,setMainCategoryList]=useState([])
    let AllData= props.data;
    useImperativeHandle(ref, () => ({
        showEmployeModal() {
            setAddEmploye(true)
        }    
    }));
    const nav = useNavigate();
    const handleSubmit=(e)=>{
        e.preventDefault();
        nav("#");
    }

    const getMaincategoryLists=async ()=>{
      // setLoader(true)
      await GetMainCategoryList().then((response)=>{
           if(response.status==200){
              // setListData(response?.data?.data)
              // setSearchBox(response?.data.data.map((item)=>item?.po_no))
              // setLoader(false)
            
              setMainCategoryList(response.data.data)
           }          
              }).catch((err)=>console.log(err))
    }
    
    
    let threeDot=mainCategoryList?.map((item)=>{
      if(AllData?.[0]?.category?.includes(item?._id)){
        return item.category_name
      }else{
        return
      }
    }).filter((item)=>item!==undefined)

    useEffect(()=>{
      getMaincategoryLists()
    },[])
  return (
    <>
    <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
        <div className="offcanvas-header">
        <div className='d-flex col-4'>
            <h5 className="modal-title" id="#gridSystemModal">{'Regretted RFQ'}</h5>
            <div className='col-6'>
                            <Dropdown className="dropdown">
                          <Dropdown.Toggle
                            variant="primary light"
                            className=" i-false p-0 sharp"
                          >
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="19" cy="12" r="2" />
                              </g>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                            Project No :<span className='text-primary'>{AllData?.[0]?.po_no}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Category :<span className='text-primary'>{threeDot?.join(',')}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Type :<span className='text-primary'>{AllData?.[0]?.rfq_type}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Status :<span className='text-primary'>{AllData?.[0]?.rfq_status}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Description :<span className='text-primary'>{AllData?.[0]?.rfq_description}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Date :<span className='text-primary'>{AllData?.[0]?.rfq_date}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Closing Date:<span className='text-primary'>{AllData?.[0]?.rfq_closing_date}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Work Completion Date:<span className='text-primary'>{AllData?.[0]?.completion_date}</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
</div>
            <button type="button" className="btn-close" 
                onClick={()=>setAddEmploye(false)}
            >
                <i className="fa-solid fa-xmark"></i>
            </button>
        </div>
        <div className="offcanvas-body">
            <div className="container-fluid">
            {/* <div className='row col-6'>
                            <div className='col-6 mt-2'>
                            <label>RFQ NO. : <span className='text-primary'>{AllData?.rfq_no}</span>
                          
                            </label>
                            </div>
                        <div className='col-6'>
                            <Dropdown className="dropdown">
                          <Dropdown.Toggle
                            variant="primary light"
                            className=" i-false p-0 sharp"
                          >
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="19" cy="12" r="2" />
                              </g>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                            Project No :<span className='text-primary'>{AllData?.po_no}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Category :<span className='text-primary'>{AllData?.category?.join(',')}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Type :<span className='text-primary'>{AllData?.rfq_type}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Status :<span className='text-primary'>{AllData?.rfq_status}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Description :<span className='text-primary'>{AllData?.rfq_description}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Date :<span className='text-primary'>{AllData?.rfq_date}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Closing Date:<span className='text-primary'>{AllData?.rfq_closing_date}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Work Completion Date:<span className='text-primary'>{AllData?.completion_date}</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        </div> */}
              
                    {/* <div className="row">
                        <div className="col-xl-6 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Sub Assembly<span className="text-danger">*</span></label>
                            <input type="text" className="text-success form-control font-weight-bold" id="exampleFormControlInput1" placeholder="" value={AllData?.sub_assembly?AllData?.sub_assembly:"N/A"} disabled />
                        </div>	
                        <div className="col-xl-6 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Weight/Unit/Quantity<span className="text-danger">*</span></label>
                            <input type="text" className="text-success form-control font-weight-bold" id="exampleFormControlInput1" placeholder="" value={`${AllData?.weight?AllData?.weight:"N/A"} / ${AllData?.unit_of_measurment} / ${AllData?.quantity}`} disabled />
                        </div>	
                        <div className="col-xl-6 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Quoted Price<span className="text-danger">*</span></label>
                            <input type="text" className="text-success form-control font-weight-bold" id="exampleFormControlInput1" placeholder="" value={AllData?.quoted_price?AllData?.quoted_price:"N/A"} disabled />
                        </div>
                        <div className="col-xl-6 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Status<span className="text-danger">*</span></label>
                            <input type="text" className="text-success form-control font-weight-bold" id="exampleFormControlInput1" placeholder="" value={AllData?.status?AllData?.status:"N/A"} disabled />
                        </div>		
                  
                     
                       
                    </div> */}
<Table responsive bordered className="verticle-middle">
                  <thead>
                    <tr>
                      <th scope="col">Sub Assembly</th>
                      <th scope="col">Quoted Price</th>
                      <th scope="col">Weight/Unit/Quantity</th>
                      <th scope="col">Status</th>
                    
                    
                    </tr>
                  </thead>
                  <tbody>

               { AllData&&AllData?.length&& AllData?.map((item)=> <tr>
                      <td>{item.sub_assembly_name}</td>
                      <td>{item?.quoted_price} </td>
                      <td> {item.weight}/{item.unit_of_measurment}/{item.quantity}</td>
                      <td>{item.vendor_rfq_status}</td>
                    
                     
                    
               
                    </tr>
               )
}
   
                  </tbody>
                </Table> 

                  {/* { AllData&&AllData.sub_assembly&& <div>
                        <button type="submit" className="btn btn-primary me-1" onClick={()=>{onAccept()}}>Accept</button>
                        <Link  onClick={()=>{
                           
                            onReject()
                        }} className="btn btn-danger light ms-1">Reject</Link>
                    </div>} */}
              
            </div>
        </div>
    </Offcanvas>     
</>

  )
})

export default RegrettedRfqCanvas