import axios from "axios";
import { AWARD_UPDATE_RFQs_API, COMPLETED_RFQ_API, GET_ACTIVE_RFQ_API, GET_ALL_PROCESS_DATA, GET_AWARDED_RFQ_API, GET_MAINCATEGORY_LIST, REGRATED_RFQ_API, SHOW_VENDOR_PROCESS_DATA, SO_TRACKING_ADD_REMARK_API, SO_TRACKING_GET_REMARK_API, SO_TRACKING_RFQ_API, SO_TRACKING_STATUS_UPDATE, UNAWARDED_RFQ_API, UPDATE_RFQ_API } from "../Api";

export function GetAwardedRfqList(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        GET_AWARDED_RFQ_API,
        postData,
    );
}

export function GetActiveRfqList(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        GET_ACTIVE_RFQ_API,
        postData,
    );
}

export function GetMainCategoryList(id) {
    // const postData = {
    //     vendor_auto_id:id,
    // };
    return axios.get(
        GET_MAINCATEGORY_LIST

    );
}

export function UpdateRfq(data) {
    // const postData = {
    //     vendor_auto_id:id,
    //     rfq_auto_id:rfqId,
    //     quoted_price:amount,
    //     "other_rfq_documents[]":otherDocument
    // };
    const headers = {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        // Add more headers if required
    };
    return axios.post(
        UPDATE_RFQ_API,
        data,
        {headers:headers}
    );

}


export function awardeUpdateRfq(id,masterId,vendorId,status) {
    const postData = {
        vendor_auto_id:id,
        master_rfq_id:masterId,
        vendor_rfq_id:vendorId,
        vendor_rfq_status:status
    };
    return axios.post(
        AWARD_UPDATE_RFQs_API,
        postData,
    );
}



export function GetRegratedRfqList(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        REGRATED_RFQ_API,
        postData,
    );
}


export function GetUnawardedRfqList(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        UNAWARDED_RFQ_API,
        postData,
    );
}

export function GetCompletedRfqList(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        COMPLETED_RFQ_API,
        postData,
    );
}

export function GetSoTrakingList(id) {
    const postData = {
        vendor_auto_id:id,
    };
    return axios.post(
        SO_TRACKING_RFQ_API,
        postData,
    );
}
export function GetSoTrakingRemarkList(pno,sub) {
    const postData = {
        po_no:pno,
        sub_assembly_name:sub,
    };
    return axios.post(
        SO_TRACKING_GET_REMARK_API,
        postData,
    );
}
export function addSoTrakingRemarkList(pno,sub,remark) {
    const postData = {
        po_no:pno,
        sub_assembly_name:sub,
        remark_status:remark
    };
    return axios.post(
        SO_TRACKING_ADD_REMARK_API,
        postData,
    );
}

export function submitCompleteSoTraking(vendor_auto_id,rfq_auto_id,status) {
    const postData = {
        vendor_auto_id:vendor_auto_id,
        rfq_auto_id:rfq_auto_id,
        status:status
    };
    return axios.post(
        SO_TRACKING_STATUS_UPDATE,
        postData,
    );
}


export function GetAllProcessData(postData) {
    
    return axios.post(
        GET_ALL_PROCESS_DATA,
        postData,
    );
}


export function ShowAllProcessData(postData) {
    
    return axios.post(
        SHOW_VENDOR_PROCESS_DATA,
        postData,
    );
}


