import React, { useState, forwardRef, useImperativeHandle, useEffect  } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Badge, Button, Dropdown, Offcanvas, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { GetMainCategoryList, UpdateRfq } from '../../../services/RFQ/Rfq';
import { useSelector } from 'react-redux';
import swal from "sweetalert";	
const ActiveRfqCanvas=forwardRef((props, ref) =>{
    const { updateSuccessfuly=()=>{} } = props;
    let AllDatas= props.data;
    console.log('AllData.quoted_priceAllData.quoted_price',AllDatas)
 const [amount,setAmount]=useState('')
    const [addEmploye , setAddEmploye] = useState(false);
    // const [otherDocument,setOtherDocument]=useState(AllDatas?.other_rfq_documents?AllDatas?.other_rfq_documents[0]:{});
    const [otherDocument,setOtherDocument]=useState('');
    const [mainCategoryList,setMainCategoryList]=useState([])
    const [term,setTerm]=useState('')
    const reduxData = useSelector(state => state.auth.auth.data);
    const id = useSelector(state => state.auth.auth.data.id);

    useImperativeHandle(ref, () => ({
        showEmployeModal() {
            setAddEmploye(true)
        }    
    }));
    const nav = useNavigate();
    const handleSubmit=(e)=>{
        e.preventDefault();
        nav("#");
    }



console.log('otherDocument',reduxData)
   
const getMaincategoryLists=async ()=>{
  // setLoader(true)
  await GetMainCategoryList().then((response)=>{
       if(response.status==200){
          // setListData(response?.data?.data)
          // setSearchBox(response?.data.data.map((item)=>item?.po_no))
          // setLoader(false)
        
          setMainCategoryList(response.data.data)
       }          
          }).catch((err)=>console.log(err))
}


let threeDot=mainCategoryList?.map((item)=>{
  if(AllDatas?.[0]?.category.includes(item?._id)){
    return item.category_name
  }else{
    return
  }
}).filter((item)=>item!==undefined)

    const UpdateRfqItem=async (rfq_auto_id)=>{
      const formData= new FormData();
		formData.append("vendor_auto_id",id)
		formData.append("rfq_auto_id",rfq_auto_id)
    formData.append("other_rfq_documents[]",otherDocument)
    formData.append("Vendor_term_condition[]",term)
		formData.append("quoted_price",amount)
		
        await UpdateRfq(formData).then((response)=>{
            if(response.status){
                
                setAddEmploye(false)
                 swal("Active RFQ is updated!.")
                setAmount('')
                setTimeout(() => {
                  updateSuccessfuly('1')
                }, 1000);
             
            }    else{
              // swal("Active RFQ is updated!.")
            }      
               }).catch((err)=>console.log(err))
              //  updateSuccessfuly('1')
    }

    useEffect(()=>{
      getMaincategoryLists()
    },[])
    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
<div className='d-flex col-4'>
					<h5 className="modal-title col-6" id="#gridSystemModal">{'Active RFQ'}</h5>
          <div className='col-6'>
                            <Dropdown className="dropdown">
                          <Dropdown.Toggle
                            variant="primary light"
                            className=" i-false p-0 sharp"
                          >
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="19" cy="12" r="2" />
                              </g>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                            Project No :<span className='text-primary'>{AllDatas?.[0]?.po_no}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Category :<span className='text-primary'>{threeDot?.join(',')}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Type :<span className='text-primary'>{AllDatas?.[0]?.rfq_type}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Status :<span className='text-primary'>{AllDatas?.[0]?.rfq_status}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Description :<span className='text-primary'>{AllDatas?.[0]?.rfq_description}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Date :<span className='text-primary'>{AllDatas?.[0]?.rfq_date}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Closing Date:<span className='text-primary'>{AllDatas?.[0]?.rfq_closing_date}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Work Completion Date:<span className='text-primary'>{AllDatas?.[0]?.completion_date}</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        </div>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
        {console.log('AllDatasAllDatasAllDatas',AllDatas)}
				{<div className="offcanvas-body">
                    {/* <div className="container-fluid">
                        <div className='row col-6'>
                            <div className='col-6 mt-2'>
                            <label>RFQ NO. : <span className='text-primary'>{AllData?.rfq_no}</span>
                          
                            </label>
                            </div>
                        <div className='col-6'>
                            <Dropdown className="dropdown">
                          <Dropdown.Toggle
                            variant="primary light"
                            className=" i-false p-0 sharp"
                          >
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="19" cy="12" r="2" />
                              </g>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                            Project No :<span className='text-primary'>{AllData?.po_no}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Category :<span className='text-primary'>{AllData?.category?.join(',')}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Type :<span className='text-primary'>{AllData?.rfq_type}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Status :<span className='text-primary'>{AllData?.rfq_status}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Description :<span className='text-primary'>{AllData?.rfq_description}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Date :<span className='text-primary'>{AllData?.rfq_date}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             RFQ Closing Date:<span className='text-primary'>{AllData?.rfq_closing_date}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              to="/widget-basic"
                            >
                             Work Completion Date:<span className='text-primary'>{AllData?.completion_date}</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        </div>
                      
                            <div className="row">
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Sub Assembly<span className="text-danger">*</span></label>
                                    <input type="text" className="text-success form-control font-weight-bold" id="exampleFormControlInput1" placeholder="" value={AllData?.sub_assembly?AllData?.sub_assembly:"N/A"} disabled />
                                </div>	
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Weight/Unit/Quantity<span className="text-danger">*</span></label>
                                    <input type="text" className="text-success form-control font-weight-bold" id="exampleFormControlInput1" placeholder="" value={`${AllData?.weight?AllData?.weight:"N/A"} / ${AllData?.unit_of_measurment} / ${AllData?.quantity}`} disabled />
                                </div>	
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Quoted Price<span className="text-danger">*</span></label>
                                    <input type="number" className="text-success form-control font-weight-bold" id="exampleFormControlInput1" placeholder={AllData.quoted_price} value={amount} onChange={(e)=>setAmount(String(e.target.value))}  />
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Status<span className="text-danger">*</span></label>
                                    <input type="text" className="text-success form-control font-weight-bold" id="exampleFormControlInput1" placeholder="" value={AllData?.status?AllData?.status:"N/A"} disabled />
                                </div>	

                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Upload Other Document<span className="text-danger">*</span></label>
                                    <div className="dz-default dlab-message upload-img mb-3">	
                                <form action="#" className="dropzone">
                                    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.1666 26.6667L20.4999 20L13.8333 26.6667" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20.5 20V35" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M34.4833 30.6501C36.1088 29.7638 37.393 28.3615 38.1331 26.6644C38.8731 24.9673 39.027 23.0721 38.5703 21.2779C38.1136 19.4836 37.0724 17.8926 35.6111 16.7558C34.1497 15.619 32.3514 15.0013 30.4999 15.0001H28.3999C27.8955 13.0488 26.9552 11.2373 25.6498 9.70171C24.3445 8.16614 22.708 6.94647 20.8634 6.1344C19.0189 5.32233 17.0142 4.93899 15.0001 5.01319C12.9861 5.0874 11.015 5.61722 9.23523 6.56283C7.45541 7.50844 5.91312 8.84523 4.7243 10.4727C3.53549 12.1002 2.73108 13.9759 2.37157 15.959C2.01205 17.9421 2.10678 19.9809 2.64862 21.9222C3.19047 23.8634 4.16534 25.6565 5.49994 27.1667" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M27.1666 26.6667L20.4999 20L13.8333 26.6667" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div className="fallback">
                                        <input name="file" type="file" multiple onChange={(e)=>setOtherDocument(e.target.files[0])} />   
                                    {  AllData?.other_rfq_documents&&  <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7379 2.76181H8.08493C6.00493 2.75381 4.29993 4.41181 4.25093 6.49081V17.2038C4.20493 19.3168 5.87993 21.0678 7.99293 21.1148C8.02393 21.1148 8.05393 21.1158 8.08493 21.1148H16.0739C18.1679 21.0298 19.8179 19.2998 19.8029 17.2038V8.03781L14.7379 2.76181Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.4751 2.75V5.659C14.4751 7.079 15.6231 8.23 17.0431 8.234H19.7981" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.2882 15.3585H8.88818" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.2432 11.606H8.88721" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>                                     }
                                    </div>
                                </form>
                            </div>	
                                </div>	
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Upload Terms/Condition<span className="text-danger">*</span></label>
                                    <div className="dz-default dlab-message upload-img mb-3">	
                                <form action="#" className="dropzone">
                                    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.1666 26.6667L20.4999 20L13.8333 26.6667" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20.5 20V35" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M34.4833 30.6501C36.1088 29.7638 37.393 28.3615 38.1331 26.6644C38.8731 24.9673 39.027 23.0721 38.5703 21.2779C38.1136 19.4836 37.0724 17.8926 35.6111 16.7558C34.1497 15.619 32.3514 15.0013 30.4999 15.0001H28.3999C27.8955 13.0488 26.9552 11.2373 25.6498 9.70171C24.3445 8.16614 22.708 6.94647 20.8634 6.1344C19.0189 5.32233 17.0142 4.93899 15.0001 5.01319C12.9861 5.0874 11.015 5.61722 9.23523 6.56283C7.45541 7.50844 5.91312 8.84523 4.7243 10.4727C3.53549 12.1002 2.73108 13.9759 2.37157 15.959C2.01205 17.9421 2.10678 19.9809 2.64862 21.9222C3.19047 23.8634 4.16534 25.6565 5.49994 27.1667" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M27.1666 26.6667L20.4999 20L13.8333 26.6667" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div className="fallback">
                                        <input name="file" type="file" multiple onChange={(e)=>setTerm(e.target.files[0])} /> 
                                        {  AllData?.vendor_term_conditon&&  <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7379 2.76181H8.08493C6.00493 2.75381 4.29993 4.41181 4.25093 6.49081V17.2038C4.20493 19.3168 5.87993 21.0678 7.99293 21.1148C8.02393 21.1148 8.05393 21.1158 8.08493 21.1148H16.0739C18.1679 21.0298 19.8179 19.2998 19.8029 17.2038V8.03781L14.7379 2.76181Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.4751 2.75V5.659C14.4751 7.079 15.6231 8.23 17.0431 8.234H19.7981" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.2882 15.3585H8.88818" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.2432 11.606H8.88721" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>                                     }                                       
                                    </div>
                                </form>
                            </div>	
                                </div>	
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Specification/Drawing File<span className="text-danger">*</span></label>
                                  <h6>No document</h6>
                                </div>	

                             
                             
                               
                            </div>
                          <div>
                                <button type="submit" className="btn btn-primary me-1" onClick={()=>{UpdateRfqItem()}}>Submit</button>
                                <button type="submit" className="btn btn-danger me-1" onClick={()=>{setAddEmploye(false)}}>Cancel</button>
             
                            </div>
                            <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Annexure2.pdf<span className="text-danger">*</span></label>
                                  <h6> <a href='http://35.154.217.163/new/images/RFQ/Annexure2.pdf' target='_blank'>
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
                                    </a></h6>
                                </div>	
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">commercialAcceptanceTerms.xlsx<span className="text-danger">*</span></label>
                                  <h6> <a href='http://35.154.217.163/new/images/RFQ/CommercialAcceptanceTerms.xlsx' target='_blank'>
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
                                    </a></h6>
                                </div>	
                      
                    </div> */}
                     <Table responsive bordered className="verticle-middle">
                  <thead>
                    <tr>
                      <th scope="col">Sub Assembly</th>
                      <th scope="col">Quoted Price</th>
                      <th scope="col">Weight/Unit/Quantity</th>
                      <th scope="col">Upload other Document</th>
                      <th scope="col">Upload Terms/Condition</th>
                      <th scope="col">Submit</th>
                      <th scope="col">Specification/Drawing File</th>
                    </tr>
                  </thead>
                  <tbody>

               { AllDatas&& AllDatas?.map((item)=> <tr>
                      <td>{item.sub_assembly_name}</td>
                      <td> <input type="text" defaultValue={item?.quoted_price} className="form-control"placeholder='Enter quoted price...' onChange={(e)=>{setAmount(e.target.value)}} /> </td>
                      <td> {item.weight}/{item.unit_of_measurment}/{item.quantity}</td>
                      <td><input type="file" className="form-control"placeholder='Enter quoted...' onChange={(e)=>{setOtherDocument(e.target.files[0])}}  />
                     {item?.other_rfq_documents?.length? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>:null}
                      </td>
                      <td className=''><input type="file" className="form-control"placeholder='Enter quoted...' onChange={(e)=>{setTerm(e.target.files[0])}}  />
                      {item?.vendor_term_condition?.length? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>:null}
                      </td>
                      <td onClick={()=>{
                        UpdateRfqItem(item?._id)
                        setAmount('')
                        setOtherDocument('')
                        setTerm('')
                        // setViewData(item.process_names)
                        // setTimeout(()=>{
                        //   const showImgId = document.getElementById('showImg');
                        //   showImgId.scrollIntoView({ behavior: 'smooth' });
                        // },1000)
                       
                        }}><Badge bg="" className="badge-primary light">Submit</Badge></td>
                    
                 { item.drawing_attachment?.length?  <td>  <h6> <a href={`https://app-precismart.com/images/RFQ/${item.drawing_attachment}`} target='_blank'>
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
                                    </a></h6></td>:
                      <td> No File</td>}
                    </tr>
               )
}
                   
                    
                   
                  </tbody>
                </Table>
                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Annexure2.pdf<span className="text-danger">*</span></label>
                                  <h6> <a href='https://app-precismart.com/images/RFQ/Annexure2.pdf' target='_blank'>
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
                                    </a></h6>
                                </div>	
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">commercialAcceptanceTerms.xlsx<span className="text-danger">*</span></label>
                                  <h6> <a href='https://app-precismart.com/images/RFQ/CommercialAcceptanceTerms.xlsx' target='_blank'>
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
                                    </a></h6>
                                </div>
				</div>}
        
			</Offcanvas>     
        </>
    );
})


export default ActiveRfqCanvas