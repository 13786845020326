import React, { useEffect, useState } from 'react'
import MainPagetitle from '../../layouts/MainPagetitle'
import ProfileTopMenu from './ProfileTopMenu'

import Select from 'react-select';
import { Link } from 'react-router-dom';
import IMAGES from '../../../images/big/contact-us.jpg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { UpdateVendorProfile } from '../../../services/Profile/Profile';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/bootstrap.css";
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
function ContactDetails({data,submited=()=>{}}) {
    const [isWebsite,setIsWebsite]=useState(false);
    const status = useSelector(state => state.auth.auth.data.status);
    const [formData, setFormData] = useState({
        mobile: '',
        email: '',
        name: '',
        designation: '',
      
        website: ''
    })

    useEffect(()=>{
        console.log('OOOOOOOOOOOOOOfff',data)
        if(data){

        
            setFormData({
            mobile: data?.mobile_number?data?.mobile_number:'',
            email: data?.email_id?data?.email_id:'',
            name: data?.coordination_person_name?data.coordination_person_name:'',
            designation: data?.designation?data?.designation:'',  
            website: data?.website?data?.website:''
        })
        setIsWebsite(data?.website&&data?.website!==''&&data?.website!=='undefined'?true:false)
    }
    },[data&&data])

    const mobileRegex = /^[6-9]\d{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const _validationSchema = Yup.object().shape({
        mobile:Yup.string().required('Enter number'),
        email:Yup.string().required('Enter email').matches(emailRegex,'Enter valide email'),
        name:Yup.string().required('Enter name'),
        website:isWebsite&&Yup.string().required('Enter website'),
        designation:Yup.string().required('Enter designation'),

    });

    const getprofileData=async (data)=>{
        await UpdateVendorProfile(data).then((response)=>{
             if(response.status){
                // setListData(response?.data?.data?response?.data?.data:[])
                console.log('::::::::::----::',response)
                submited('BusinessProfile')
                swal("Done", "Update Successfully!", "success")
             }          
                }).catch((err)=>console.log(err))
     }
    
    const _onSubmit = async (values, resetForm) => {
        const formData = values;
        getprofileData({
            'vendor_auto_id':data?._id ,
    'organization_name': data?.organization_name,
    'email_id':values?.email,
    'mobile_number':values?.mobile,
    'pan_no': data?.pan,
    'coordination_person_name': values?.name,
    'designation': values?.designation,
    'country_code': data?.country_code,
    'country': data?.country,
    'primary_category': data?.primary_category,
    'salutation': data?.salutation,
    'firstname': data?.firstname,
    'lastname': data.lastname,
    'username': data.username,
    'state': data?.state,
    'city': data?.city,
    'area': data?.area,
    'address': data?.address,
    'website':isWebsite?values?.website:''
        })
        console.log('________',formData)
    }

    return (
        <>


            <div className="basic-form">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4" >
                            <div className="clearfix">
                                <div className="card card-bx profile-card author-profile m-b30">
                                    <div className="card-body">
                                        <div className="p-5">
                                            <img src={IMAGES} alt="" className="w-100" style={{ borderRadius: "10px" }} />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            <div className="card profile-card card-bx m-b30">
                                {/* <div className="card-header">
                                      <h6 className="title">Account setup</h6>
                                  </div> */}
                                <Formik
                                    initialValues={formData}
                                    enableReinitialize
                                    validationSchema={_validationSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        _onSubmit(values, resetForm);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        resetForm,

                                        /* and other goodies */
                                    }) => (

                                        <form className="profile-form" onSubmit={handleSubmit}>
                                            <div className="card-body">
                                                <div className="row">
{console.log('valaaaaa',values)}
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Mobile Number*</label>
                                           {data?.mobile_number ? <input
                                                        disabled={status&&true}
                                                            name='mobile'
                                                            value={values.mobile}
                                                            touched={touched.mobile}
                                                            errors={errors.mobile}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder='Enter number'
                                                            type="text" className="form-control" />
                                                            :
                                                                           <PhoneInput
                                                                           name='mobile'
																				country={"in"}
																			enableSearch={true}
																			 value={values.mobile}
																			placeholder="Enter your phone number"

																			onChange={(e)=>setFieldValue('mobile',e)}
																			dropdownOptions={{ showCountrySelect: false }}
																			// disableCountryCode={true}
																			inputClass={"phoneSelector"}

																		/>}
                                                            
                                                                       {touched.mobile && errors.mobile ? (
        <span className="text-danger">{errors.mobile}</span>
      ) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Email address*</label>
                                                        <input
                                                         disabled={status&&true}
                                                            name='email'
                                                            value={values.email}
                                                            touched={touched.email}
                                                            errors={errors.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" placeholder='demo@gmail.com' />
                                                        {touched.email && errors.email ? (
        <span className="text-danger">{errors.email}</span>
      ) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Contact Person Name*</label>
                                                        <input
                                                            name='name'
                                                            value={values.name}
                                                            touched={touched.name}
                                                            errors={errors.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" placeholder='Enter name' />
                                                               {touched.name && errors.name ? (
        <span className="text-danger">{errors.name}</span>
      ) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Designation*</label>
                                                        <input
                                                            name='designation'
                                                            value={values.designation}
                                                            touched={touched.designation}
                                                            errors={errors.designation}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" />
                                                                {touched.designation && errors.designation ? (
        <span className="text-danger">{errors.designation}</span>
      ) : null}
                                                    </div>

                                                    <div className='col-sm-12 m-b30'>
                                                        <label className="form-label m-2">Do you have website?</label>
                                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                                            <input onChange={() => { setIsWebsite(true) }} checked={isWebsite ? true : false} type="radio" className="form-check-input" id="customRadioBox987" name="optradioCustom1" />
                                                            <label className="form-check-label" htmlFor="customRadioBox987">Yes</label>
                                                        </div>
                                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                                            <input onChange={(value) => { setIsWebsite(false) }} checked={isWebsite ? false : true} type="radio" className="form-check-input" id="customRadioBox988" name="optradioCustom1" />
                                                            <label className="form-check-label" htmlFor="customRadioBox988">No</label>
                                                        </div>
                                                    </div>
                                                    {isWebsite && <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Website*</label>
                                                        <input
                                                            name='website'
                                                            value={values.website}
                                                            touched={touched.website}
                                                            errors={errors.website}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" />
                                                             {touched.website && errors.website ? (
        <span className="text-danger">{errors.website}</span>
      ) : null}
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-primary">Submit</button>

                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )
}

export default ContactDetails