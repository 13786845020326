import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

function ProfileTopMenu({tabNumber=()=>{},tabName}) {
    const status = useSelector(state => state.auth.auth.data.status);
    const [tab,setTab]=useState(1)
    useEffect(()=>{
 tabNumber(tab)
    },[tab])

    useEffect(()=>{
        if(!status){
        if(tab!==5&&tabName=='contactDetails'){
            setTab(tab+1)
        }
        if(tab!==5&&tabName=='BusinessProfile'){
            setTab(tab+1)
        }
        if(tab!==5&&tabName=='finishialDetail'){
            setTab(tab+1)
        }
        if(tab!==5&&tabName=='BankDetail'){
            setTab(tab+1)
        }
    }
    },[tabName])
  return (
    <>
   {status?
 <ul className="nav nav-tabs style-1 mb-4" tab="pills-tab" role="tablist">
<li className="nav-item" onClick={()=>setTab(1)}>
   <Link  className={`nav-link ${tab === 1 ? 'active' : ''}`}>Key Details</Link>
</li>
<li className="nav-item" onClick={()=>setTab(2)}>
   <Link   className={`nav-link border-s-1 ${tab === 2 ? 'active' : ''}`}>Contact Details</Link>
</li>
<li className="nav-item" onClick={()=>setTab(3)}> 
   <Link  className={`nav-link ${tab === 3 ? 'active' : ''}`}>Business Profile</Link>
</li>
<li className="nav-item" onClick={()=>setTab(4)}>
   <Link className={`nav-link ${tab === 4 ? 'active' : ''}`}>Financial Details</Link>
</li>
<li className="nav-item" onClick={()=>setTab(5)}>
   <Link className={`nav-link ${tab === 5 ? 'active' : ''}`}>Bank Details</Link>
</li>

</ul> 
   : <ul className="nav nav-tabs style-1 mb-4" tab="pills-tab" role="tablist">
     <li className="nav-item" onClick={()=>setTab(1)}>
        <Link  className={`nav-link ${tab === 1 ? 'active' : ''}`}>Key Details</Link>
    </li>
    <li className="nav-item" onClick={()=>tabName=='contactDetails'&&setTab(2)}>
        <Link   className={`nav-link border-s-1 ${tab === 2 ? 'active' : ''}`}>Contact Details</Link>
    </li>
    <li className="nav-item" onClick={()=>tabName=='BusinessProfile'&&setTab(3)}> 
        <Link  className={`nav-link ${tab === 3 ? 'active' : ''}`}>Business Profile</Link>
    </li>
    <li className="nav-item" onClick={()=>tabName=='finishialDetail'&&setTab(4)}>
        <Link className={`nav-link ${tab === 4 ? 'active' : ''}`}>Financial Details</Link>
    </li>
    <li className="nav-item" onClick={()=>tabName=='BankDetail'&&setTab(5)}>
        <Link className={`nav-link ${tab === 5 ? 'active' : ''}`}>Bank Details</Link>
    </li>
   
</ul>
}
</>
  )
}

export default ProfileTopMenu


{/* <ul className="nav nav-tabs style-1 mb-4" tab="pills-tab" role="tablist">
<li className="nav-item" onClick={()=>setTab(1)}>
   <Link  className={`nav-link ${tab === 1 ? 'active' : ''}`}>Key Details</Link>
</li>
<li className="nav-item" onClick={()=>setTab(2)}>
   <Link   className={`nav-link border-s-1 ${tab === 2 ? 'active' : ''}`}>Contact Details</Link>
</li>
<li className="nav-item" onClick={()=>setTab(3)}> 
   <Link  className={`nav-link ${tab === 3 ? 'active' : ''}`}>Buisness Profile</Link>
</li>
<li className="nav-item" onClick={()=>setTab(4)}>
   <Link className={`nav-link ${tab === 4 ? 'active' : ''}`}>Finacial Details</Link>
</li>
<li className="nav-item" onClick={()=>setTab(5)}>
   <Link className={`nav-link ${tab === 5 ? 'active' : ''}`}>Bank Details</Link>
</li>

</ul> */}