import React, { useEffect, useState } from 'react'
import MainPagetitle from '../../layouts/MainPagetitle'
import ProfileTopMenu from './ProfileTopMenu'
import Select from 'react-select';
import {Link} from 'react-router-dom';
import  IMAGES  from '../../../images/big/finance.jpg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { UpdateVendorFinincialProfile, UpdateVendorProfile } from '../../../services/Profile/Profile';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
function FinancialDetails({data,submited=()=>{}}) {
    const profile = useSelector(state => state.auth.auth.data);
    console.log('profile----------------',profile)
    const [formData, setFormData] = useState({
        year: '',
        turnOver: '',
        currentAssets: '',
        liabilities: '',
      
        Euity: '',
        Debt:'',
        totalAssets:'',
        netProfit:''
    });
    const [financial,setFinancial]=useState({})


    const _validationSchema = Yup.object().shape({
        year:Yup.string().required(' year is required'),
        turnOver:Yup.string().required('turnOver is requied'),
       
        // mobile:Yup.string().required('Enter mobile number').matches(mobileRegex,'Enter valide number'),
        // email:Yup.string().required('Enter email').matches(emailRegex,'Enter valide email'),
        // name:Yup.string().required('Enter name'),
        // website:isWebsite&&Yup.string().required('Enter website'),
        // designation:Yup.string().required('Enter designation'),
       
    });

   
        // Function to handle change and filter non-numeric input
        const handleNumericChange = (event,handleChange) => {
          const { name, value } = event.target;
          // Use regex to allow only numbers
          const numericValue = value.replace(/\D/g, '');
          handleChange({
            target: {
              name,
              value: numericValue
            }
          });
        };
        
    useEffect(()=>{
        console.log('datadata',data);
        if(data){

        
            setFormData({
                year: data?.finincial_year,
                turnOver: data?.turnover,
                currentAssets: data?.current_assets,
                liabilities: data?.current_liabilities,
              
                Euity: data?.equity,
                Debt:data?.total_debt,
                totalAssets:data?.total_assets,
                netProfit:data?.net_profit
            })
        
    }
    },[data&&data])

    const getprofileData=async (data)=>{
        await UpdateVendorFinincialProfile(data).then((response)=>{
             if(response.status){
                // setListData(response?.data?.data?response?.data?.data:[])
                console.log('::::::::::----::',response)
                submited('BankDetail');
                swal("Done", "Update Successfully!", "success")
             }          
                }).catch((err)=>console.log(err))
     }
    
    const _onSubmit = async (values, resetForm) => {
     

        const formData = new FormData();

        let currentRatio = Number(values?.currentAssets?values?.currentAssets:0 )/ Number(values.liabilities?values.liabilities:0);
let quickRatio = Number(values?.currentAssets?values?.currentAssets:0) / Number(values.liabilities?values.liabilities:0 );
// let dscr = (_debt + values.netProfit + _interest) / values.liabilities;
// Populate the FormData object with the key-value pairs from the object
formData.append('_id', profile?.id);
formData.append('vendor_auto_id', profile?.id);
formData.append('current_assets', values.currentAssets);
formData.append('current_liabilities', values.liabilities);
formData.append('inventory', '');
formData.append('account_receivable', '');
formData.append('account_payble', '');
formData.append('total_payable', '');
formData.append('total_debt', values?.Debt);
formData.append('total_assets', values.totalAssets);
formData.append('current_ratio', currentRatio.toFixed(2));
formData.append('quick_ratio', quickRatio.toFixed(2));
formData.append('finincial_year', values.year);
formData.append('turnover', values.turnOver);
formData.append('equity', values.Euity);
formData.append('net_profit', values.netProfit);
formData.append('interest', '');
formData.append('deratio', '1.00');
formData.append('dscr', '0.0');
formData.append('debt_equity', values.Debt);
{data?.register_date&&formData.append('register_date', data.register_date?data.register_date:'')};
{data?.updated_at&&formData.append('updated_at', data.updated_at? data.updated_at:'')};
{data?.created_at&&formData.append('created_at', data.created_at? data.created_at:'')};
formData.append('finincial_statement', financial);
        getprofileData(formData)
       
    }

    return (
      <div className="basic-form">
                      
                      <div className="container-fluid">
                            <div className="row">
                            <div className="col-xl-3 col-lg-4" >
                              <div className="clearfix">
                                  <div className="card card-bx profile-card author-profile m-b30">
                                      <div className="card-body">
                                          <div className="p-5">
                                              {/* <div className="author-profile">
                                                  <div className="author-media">
                                                      <img src={IMAGES.Tab1} alt="" />
                                                      <div className="upload-link" title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                                                          <input type="file" className="update-flie" />
                                                          <i className="fa fa-camera"></i>
                                                      </div>
                                                  </div>
                                                  
                                              </div> */}
                                            <img src={IMAGES} alt="" className="w-100" style={{borderRadius:"10px"}} />
                   
                                          </div>
                                         
                                      </div>
                                     
                                  </div>
                              </div>
                          </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="card profile-card card-bx m-b30">
                                        {/* <div className="card-header">
                                            <h6 className="title">Account setup</h6>
                                        </div> */}
                                         <Formik
                                    initialValues={formData}
                                    enableReinitialize
                                    validationSchema={_validationSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        _onSubmit(values, resetForm);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        resetForm,

                                        /* and other goodies */
                                    }) => (

                                        <form className="profile-form" onSubmit={handleSubmit}>
                                            <div className="card-body">
                                                <div className="row">
                                                 
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Finacial Year*</label>
                                                        <input
                                                         name='year'
                                                         value={values.year}
                                                         touched={touched.year}
                                                         errors={errors.year}
                                                         onChange={handleChange}
                                                         onBlur={handleBlur}
                                                         type="text" className="form-control" />
                                                           {touched.year && errors.year ? (
                                                           <span className="text-danger">{errors.year}</span>) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Turn Over*</label>
                                                        <input name='turnOver'
                                                         value={values.turnOver}
                                                         touched={touched.turnOver}
                                                         errors={errors.turnOver}                                                       
                                                         onChange={(e)=>handleNumericChange(e,handleChange)}
                                                         onBlur={handleBlur}
                                                         pattern="[0-9]+"
                                                         type="text" className="form-control" />
                                                           {touched.turnOver && errors.turnOver ? (
                                                           <span className="text-danger">{errors.turnOver}</span>) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Current Assets</label>
                                                        <input 
                                                        name='currentAssets'
                                                         value={values.currentAssets}
                                                         touched={touched.currentAssets}
                                                         errors={errors.currentAssets}
                                                         onChange={(e)=>handleNumericChange(e,handleChange)}
                                                         onBlur={handleBlur}
                                                         type="text" className="form-control" />
                                                           {touched.currentAssets && errors.currentAssets ? (
                                                           <span className="text-danger">{errors.currentAssets}</span>) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Current Liabilities</label>
                                                        <input 
                                                        name='liabilities'
                                                        value={values.liabilities}
                                                         touched={touched.liabilities}
                                                         errors={errors.liabilities}
                                                         onChange={(e)=>handleNumericChange(e,handleChange)}
                                                         onBlur={handleBlur}
                                                         type="text" className="form-control" />
                                                           {touched.liabilities && errors.liabilities ? (
                                                           <span className="text-danger">{errors.liabilities}</span>) : null}
                                                    </div>
                                                   
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Equity</label>
                                                        <input 
                                                         name='Euity'
                                                        value={values.Euity}
                                                         touched={touched.Euity}
                                                         errors={errors.Euity}
                                                         onChange={(e)=>handleNumericChange(e,handleChange)}
                                                         onBlur={handleBlur}
                                                         type="text" className="form-control" />
                                                           {touched.Euity && errors.Euity ? (
                                                           <span className="text-danger">{errors.Euity}</span>) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Total Debt</label>
                                                        <input 
                                                         name='Debt'
                                                        value={values.Debt}
                                                         touched={touched.Debt}
                                                         errors={errors.Debt}
                                                         onChange={(e)=>handleNumericChange(e,handleChange)}
                                                         onBlur={handleBlur}
                                                         type="text" className="form-control" />
                                                           {touched.Debt && errors.Debt ? (
                                                           <span className="text-danger">{errors.Debt}</span>) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Total Assets</label>
                                                        <input 
                                                        name='totalAssets'
                                                         value={values.totalAssets}
                                                         touched={touched.totalAssets}
                                                         errors={errors.totalAssets}
                                                         onChange={(e)=>handleNumericChange(e,handleChange)}
                                                         onBlur={handleBlur}
                                                         type="text" className="form-control" />
                                                           {touched.totalAssets && errors.totalAssets ? (
                                                           <span className="text-danger">{errors.totalAssets}</span>) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Net Profilt</label>
                                                        <input value={values.netProfit}
                                                        name='netProfit'
                                                         touched={touched.netProfit}
                                                         errors={errors.netProfit}
                                                         onChange={(e)=>handleNumericChange(e,handleChange)}
                                                         onBlur={handleBlur}
                                                         type="text" className="form-control" />
                                                           {touched.netProfit && errors.netProfit ? (
                                                           <span className="text-danger">{errors.netProfit}</span>) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Upload Balance Sheet and P/L</label>
                                                    <input type="file" className="form-control" multiple onChange={(e)=>{
                                                setFinancial(e.target.files[0])
                                               
                                                }} />
                                                { (data?.finincial_statement!==''&& data?.finincial_statement!==null )&& <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>}
                                                  </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-primary">Submit</button>
                                              
                                            </div>
                                            </form>
                                    )}
                                </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>     
                      
                    
                 
                  </div>
    )
  }
export default FinancialDetails