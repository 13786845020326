import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../images/logo/logo-full.png';
import LogoWhite from '../../images/logo/logofull-white.png';
import bg6 from '../../images/background/bg6.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { createPassword, createPasswordCustomer } from '../../services/AuthService';
import { loginConfirmedAction } from '../../store/actions/AuthActions';
function CreatePassword(props) {
    const setData = useSelector(state => state.auth.auth.data);

  
    let errorsObj = { password: '',confirmPassword:'' };
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    const [confirmPassword,setConfirmPassword]=useState('')
    const localData=JSON.parse(localStorage.getItem('userDetails'));
    const navigate = useNavigate();
    const dispatch = useDispatch();
	console.log('setData-------------',localData.data.user_type)
    const onCreate=()=>{

		if(localData.data.user_type!=='Customer'){

			createPassword(localData.data.vendor_id, password)
			.then((response) => { 
				console.log('response-----------11111',response)
				 dispatch(loginConfirmedAction(localData));
	navigate('/profile')
			}) .catch((error) => {console.log(error)})
	
		}else{
			createPasswordCustomer(localData.data.id, password)
			.then((response) => { 
				console.log('response-----------22222',response)
				 dispatch(loginConfirmedAction(localData));
	navigate('/dashboard')
			}) .catch((error) => {console.log(error)})
		}
      
    }

    function onCreatePassword(e) {
      
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
	
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		if (confirmPassword ==='' ) {
			errorObj.confirmPassword = 'Write currect password';
			error = true;
		}
		if(password!==confirmPassword){
			errorObj.confirmPassword = 'Password is mismatched';
			// return;
			error = true;
		}
		setErrors(errorObj);
		
		if (error) {
			return;
		}

        if(password==confirmPassword){
            onCreate()
        }
		// dispatch(loadingToggleAction(true));
		// dispatch(loginAction(email, password,user, navigate));
	}
  return (
    <div className="page-wraper">
			<div className="browse-job login-style3">
				<div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "100vh" }}>
					<div className="row gx-0">
						<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
							<div id="mCSB_1" className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" style={{ maxHeight: "653px" }}>
								<div id="mCSB_1_container" className="mCSB_container" style={{ position: "relative", top: "0", left: "0", dir: "ltr" }}>
									<div className="login-form style-2">
										<div className="card-body">
											<div className="logo-header">
												<Link to={"#"} className="logo"><img src={logo} alt="" className="width-230 light-logo" /></Link>
												<Link to={"#"} className="logo"><img src={LogoWhite} alt="" className="width-230 dark-logo" /></Link>
											</div>
											<div className="nav nav-tabs border-bottom-0" >
												<div className="tab-content w-100" id="nav-tabContent">
													<div className="tab-pane fade active show" id="nav-personal">
														{props.errorMessage && (
															<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
																{props.errorMessage}
															</div>
														)}
														{props.successMessage && (
															<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
																{props.successMessage}
															</div>
														)}
														<form className=" dz-form pb-3" onSubmit={onCreatePassword}>
															<h3 className="form-title m-t0">Create password</h3>
															<div className="dz-separator-outer m-b5">
																<div className="dz-separator bg-primary style-liner"></div>
															</div>
															
															<div className="form-group mb-3">
																<input type="password" className="form-control" value={password} placeholder='Enter new Password' onChange={(e) => setPassword(e.target.value)} />
																{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
															</div>
															<div className="form-group mb-3">

																<input type="password" className="form-control" value={confirmPassword} placeholder='Enter Confirm Password' onChange={(e) => setConfirmPassword(e.target.value)} />
																{errors.confirmPassword && <div className="text-danger fs-12">{errors.confirmPassword}</div>}
															</div>
											

															<div className="form-group text-left mb-5 d-flex">
																<button type="submit" className="btn btn-primary d-flex m-r5">Create Password</button>
																
															</div>
															
														</form>
														
													</div>
                                                    <div className="form-group text-left mb-5 d-flex">
															<Link to={'/login'}>	<button type="submit" className="btn btn-primary d-flex m-r5">Go back</button>
                                                            </Link>
															</div>

												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  )
}

export default CreatePassword