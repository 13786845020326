import React, { useEffect, useState } from 'react';
import IMAGES from '../../../images/big/key.jpg';
import MainPagetitle from '../../layouts/MainPagetitle';
import { updatePasswordVendor } from '../../../services/AuthService';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

function ChangePassword() {
    const navigate = useNavigate();
    const id = useSelector(state => state.auth.auth.data.id);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);
  

    const getprofileData = async () => {
        const postData = {
            vendor_auto_id: id,
            oldp: oldPassword,
            newp: newPassword
        };

        try {
            const response = await updatePasswordVendor(postData);
            if (response?.data?.status) {
                console.log('::::::::::----::', response);
                swal("Password is updated!.");
                navigate('/dashboard');
            }
            else{
                swal(response?.data?.msg);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (newPassword === confirmPassword) {
            getprofileData();
        } else {
            setError(true);
        }
    };

    return (
        <>
            <MainPagetitle mainTitle="Change Password" />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <div className="clearfix">
                            <div className="card card-bx profile-card author-profile m-b30">
                                <div className="card-body">
                                    <div className="p-5">
                                        <img src={IMAGES} alt="" className="w-100" style={{ borderRadius: "10px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <form className="profile-form" onSubmit={onSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Old Password*</label>
                                            <input onChange={(e) => setOldPassword(e.target.value)} required type="password" className="form-control" />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">New Password*</label>
                                            <input onChange={(e) => setNewPassword(e.target.value)} required type="password" className="form-control" />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Confirm Password*</label>
                                            <input onChange={(e) => setConfirmPassword(e.target.value)} required type="password" className="form-control" />
                                            {newPassword !== confirmPassword && <p style={{ color: 'red' }}>Passwords do not match</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
