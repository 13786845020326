import React, { useEffect, useState } from 'react'
import MainPagetitle from '../../layouts/MainPagetitle'
import ProfileTopMenu from './ProfileTopMenu'
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { IMAGES } from '../../constant/theme';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { UpdateVendorBankProfile } from '../../../services/Profile/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { Logout } from '../../../store/actions/AuthActions';
import { ToastContainer, toast } from "react-toastify";
import swal from 'sweetalert';
function BankDetails({data,submited=()=>{}}) {
    const status = useSelector(state => state.auth.auth.data.status);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(state => state.auth.auth.data);
    var ifscRegex = /^[A-Z]{4}[0][\d]{6}$/;
    const [formData, setFormData] = useState({
        bankName: '',
        beneficiary: '',
        accountName: '',
        accountNumber: '',

        ifc: '',
        bankAdress: '',
        file: '',

    })

    useEffect(()=>{
        console.log('datadata',data);
        if(data){
            setFormData({
        bankName: data?.bank_name,
        beneficiary:data?.beneficiary_name,
        accountName: data?.account_name,
        accountNumber: data?.account_number,
        ifc: data?.IFSC_code,
        bankAdress: data?.bank_address, 
        file:''  
            })     
    }
    },[data&&data])

    const _validationSchema = Yup.object().shape({
        bankName: Yup.string().required(' bankName is required'),
        beneficiary: Yup.string().required(' beneficiary is required'),
        accountName: Yup.string().required(' accountName is required'),
        accountNumber: Yup.string().required(' accountNumber is required'),
        ifc: Yup.string().required(' ifsc is required').matches(ifscRegex,'Enter correct ifsc code'),
        bankAdress: Yup.string().required(' bankAdress is required'),
        
     
    });

    const notifyTopRight = () => {
		toast.success("✔️ Your vendor account activation is in progress. If you have any immediate questions or concerns, feel free to reach out to our support team at sales@precismart.com.", {
		  position: "top-right",
		  autoClose: 5000,
		  hideProgressBar: false,
		  closeOnClick: false,
		  pauseOnHover: true,
		  draggable: true,
		});
	  };

      const handleNumericChange = (event,handleChange) => {
        const { name, value } = event.target;
        // Use regex to allow only numbers
        const numericValue = value.replace(/\D/g, '');
        handleChange({
          target: {
            name,
            value: numericValue
          }
        });
      };

    const getprofileData=async (data)=>{
        await UpdateVendorBankProfile(data).then((response)=>{
            console.log('response.status',response.data.status)
             if(response.data.status=='1'){
                // setListData(response?.data?.data?response?.data?.data:[])
                console.log('::::::::::----::',response)
                swal("Done", "Update Successfully!", "success")
                
                submited('BankDetails')
if(status){
    navigate('/dashboard')
}else{
    notifyTopRight()
    setTimeout(()=>{  dispatch(Logout(navigate));},2000)
              
}
             }          
                }).catch((err)=>console.log(err))
     }
    const _onSubmit = async (values, resetForm) => {
    
        const formData = new FormData();

// Populate the FormData object with the key-value pairs from the object
formData.append('vendor_auto_id', profile.id);
formData.append('bank_name', values.bankName);
formData.append('beneficiary_name', values.beneficiary);
formData.append('account_name', values.accountName);
formData.append('account_number', values.accountNumber);
formData.append('IFSC_code', values.ifc);
formData.append('bank_address', values.bankAdress);
formData.append('cancelled_check', values.file);

        getprofileData(formData)

       
    }

    return (
        <>
            <div className="basic-form">
            <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4" >
                            <div className="clearfix">
                                <div className="card card-bx profile-card author-profile m-b30">
                                    <div className="card-body">
                                        <div className="p-5">

                                            <img src={IMAGES.Credit} alt="" className="w-100" />

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            <div className="card profile-card card-bx m-b30">
                                {/* <div className="card-header">
                                            <h6 className="title">Account setup</h6>
                                        </div> */}
                                <Formik
                                    initialValues={formData}
                                    enableReinitialize
                                    validationSchema={_validationSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        _onSubmit(values, resetForm);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        resetForm,

                                        /* and other goodies */
                                    }) => (

                                        <form className="profile-form" onSubmit={handleSubmit}>
                                            <div className="card-body">
                                                <div className="row">

                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Bank Name*</label>
                                                        <input
                                                            name='bankName'
                                                            value={values.bankName}
                                                            touched={touched.bankName}
                                                            errors={errors.bankName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" placeholder='Enter Bank Name...' />
                                                        {touched.bankName && errors.bankName ? (
                                                            <span className="text-danger">{errors.bankName}</span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Beneficiary Name*</label>
                                                        <input
                                                            name='beneficiary'
                                                            value={values.beneficiary}
                                                            touched={touched.beneficiary}
                                                            errors={errors.beneficiary}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" placeholder='Enter beneficiary...' />
                                                        {touched.beneficiary && errors.beneficiary ? (
                                                            <span className="text-danger">{errors.beneficiary}</span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Account Name*</label>
                                                        <input
                                                            name='accountName'
                                                            value={values.accountName}
                                                            touched={touched.accountName}
                                                            errors={errors.accountName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" placeholder='Enter accountName...' />
                                                        {touched.accountName && errors.accountName ? (
                                                            <span className="text-danger">{errors.accountName}</span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Account Number*</label>
                                                        <input
                                                            name='accountNumber'
                                                            value={values.accountNumber}
                                                            touched={touched.accountNumber}
                                                            errors={errors.accountNumber}
                                                            onChange={(e)=>handleNumericChange(e,handleChange)}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" placeholder='Enter accountNumber...' />
                                                        {touched.accountNumber && errors.accountNumber ? (
                                                            <span className="text-danger">{errors.accountNumber}</span>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">IFSC Code*</label>
                                                        <input
                                                            name='ifc'
                                                            value={values.ifc}
                                                            touched={touched.ifc}
                                                            errors={errors.ifc}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" placeholder='Enter ifc...' />
                                                        {touched.ifc && errors.ifc ? (
                                                            <span className="text-danger">{errors.ifc}</span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Bank Address*</label>
                                                        <input
                                                            name='bankAdress'
                                                            value={values.bankAdress}
                                                            touched={touched.bankAdress}
                                                            errors={errors.bankAdress}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text" className="form-control" placeholder='Enter bankAdress...' />
                                                        {touched.bankAdress && errors.bankAdress ? (
                                                            <span className="text-danger">{errors.bankAdress}</span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-12 m-b30">
                                                        {console.log('valuesvalues',values)}
                                                        <label className="form-label">Upload Cancelled Cheqe*</label>
                                                        <input name='file'
                                                            // value={values.file}
                                                            required={(data?.cancelled_check==''|| data?.cancelled_check==null )}
                                                            touched={touched.file}
                                                            errors={errors.file}
                                                            onChange={(e)=>setFieldValue('file',e.target.files[0])}
                                                            onBlur={handleBlur}
                                                            type="file" className="form-control" placeholder='Enter file...' />
                                                        {touched.file && errors.file ? (
                                                            <span className="text-danger">{errors.file}</span>
                                                        ) : null}
                                                         { (data?.cancelled_check!==''&& data?.cancelled_check!==null )&& <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>}
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-primary">Submit</button>

                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BankDetails