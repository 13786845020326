import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import { CREATE_PASSWORD_API, CREATE_PASSWORD_FOR_CUSTOMER_API, CUSTOMER_LOGIN, Forgot_PASSWARD, GOOGLE_LOGIN_API, LOGIN_API, REGISTER_VENDOR_API, UPDATE_PASSWORD_API,  } from './Api';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function registerVendor(data) {
    const headers = {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        // Add more headers if required
    };
    //axios call
    const postData = data;
    return axios.post(
        REGISTER_VENDOR_API ,
        postData,
        { headers: headers }
    );
}
export function registerCustomer(data) {
   
    //axios call
    const postData = data;
    return axios.post(
        CUSTOMER_LOGIN ,
        data,
     
    );
}
// export function login(email, password) {
//     const postData = {
//         email,
//         password,
//         returnSecureToken: true,
//     };
//     return axios.post(
//         `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
//         postData,
//     );
// }
export function login(email, password,userType) {
    const postData = {
        username:email,
        password:password,
        user_type:userType
    };
    return axios.post(
        LOGIN_API,
        postData,
    );
}
export function googleLogin(emaiId) {
    const postData = {
        "email_id":emaiId,
        "user_type":"Vendor",
        "login_type":"google"
      };
    return axios.post(
        GOOGLE_LOGIN_API,
        postData,
    );
}

export function ForgoutPassword(email) {
    const postData = {
        email:email,
       
    };
    return axios.post(
        Forgot_PASSWARD,
        postData,
    );
}

export function createPassword(id, password) {
    const postData = {
        vendor_id:id,
        password:password,
    
    };
    return axios.post(
        CREATE_PASSWORD_API,
        postData,
    );
}

export function createPasswordCustomer(id, password) {
    const postData = {
        customer_id:id,
        password:password,
    
    };
    return axios.post(
        CREATE_PASSWORD_FOR_CUSTOMER_API,
        postData,
    );
}

export function updatePasswordVendor(postData){
    return axios.post(
        UPDATE_PASSWORD_API,
        postData,
    );   
}


export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':            
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
     tokenDetails.expireDate=360 *1000;

    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    // setTimeout(() => {
    //     //dispatch(Logout(history));
    //     dispatch(Logout(navigate));
    // }, timer);
}

// export function checkAutoLogin(dispatch, navigate) {
//     const tokenDetailsString = localStorage.getItem('userDetails');
//     let tokenDetails = '';
//     if (!tokenDetailsString) {
//         dispatch(Logout(navigate));
// 		return;
//     }

//     tokenDetails = JSON.parse(tokenDetailsString);
//     let expireDate = new Date(tokenDetails.expireDate);
//     let todaysDate = new Date();

//     if (todaysDate > expireDate) {
//         dispatch(Logout(navigate));
//         return;
//     }
		
//     dispatch(loginConfirmedAction(tokenDetails));
	
//     const timer = expireDate.getTime() - todaysDate.getTime();
//     runLogoutTimer(dispatch, timer, navigate);
// }


export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    console.log("tokenDetailsString",tokenDetailsString)
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    dispatch(loginConfirmedAction(tokenDetails));
}



export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    }else{
        return false;
    }
}