import React from "react";
import ReactApexChart from "react-apexcharts";
// import './style.css'
class ProjectStatusBlogDonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: 0,
    };
  }

  componentDidMount() {
    this.setState({ values: this.props.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ values: this.props.value });
    }
  }

  render() {
    const { values } = this.state;
    const SecondValue = values !== 100 ? 100 - values : 0;

    const options = {
      chart: {
        type: "donut",
        width: 250,
      },
      colors: ["#3AC977", "grey", "var(--primary)", "#FF5E5E"],
      labels: ["Compete", "Pending", "Yet to Start", "Cancelled"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "90%",
            labels: {
              show: true,
              name: {
                show: true,
                offsetY: 12,
              },
              value: {
                show: true,
                fontSize: "24px",
                fontFamily: "Arial",
                fontWeight: "500",
                offsetY: -17,
              },
              total: {
                show: this.props.img ? false : true,
                fontSize: "11px",
                fontWeight: "500",
                fontFamily: "Arial",
                label: "Total projects",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    };

    return (
      <div id="projectChart" className="project-chart" style={{ position: "relative" }}>
        <ReactApexChart options={options} series={[values, SecondValue, 0, 0]} type="donut" width={250} />
        {this.props.img && (
          <img
            src={this.props.img}
            alt="Your Image"
            className="chart-image"
            width={"150px"}
            height={"auto"}

            style={{ position: "absolute", top: "59px", borderRadius: "25px",backgroundColor:'white' ,padding:'15px 0px'}}
          />
        )}
        {this.props.title&&(
          <div style={{backgroundColor:'white',width:'149px',height:'40px',position: "absolute", top: "72px", borderRadius: "10px",fontSize:'30px',textAlign:'center'}}>
{this.props.title}
          </div>
        )}
      </div>
    );
  }
}

export default ProjectStatusBlogDonutChart;
