import React, { useEffect, useRef, useState } from 'react'
import PageTitle from "../../../layouts/PageTitle";

import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Dropdown,
    ProgressBar,
  } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { GetActiveRfqList } from '../../../../services/RFQ/Rfq';
import ActiveRfqCanvas from '../../ActiveRfq/ActiveRfqCanvas';
import './style.css'
import { setDate } from 'rsuite/esm/utils/dateUtils';
function ActiveRfq() {
  const id = useSelector(state => state.auth.auth.data.id);
  const [ListData,setListData]=useState([]);
  const [siderData,setSiderData]=useState([]);
  const [refresh,setRefresh]=useState(false);
  
  console.log('ListDataListData',ListData)
  const [searchBoxData,setSearchBox]=useState([]);
  const [filterDatas,setFilterDatas]=useState([]);

  const [newfilterData,setNewFilterData]=useState([])
const[loader,setLoader]=useState(false)

  const getRfqVendor=async ()=>{
    setLoader(true)
    await GetActiveRfqList(id).then((response)=>{
         if(response.status){
            setListData(response?.data?.data)
            setSearchBox(response?.data.data.map((item)=>item?.po_no))
            setLoader(false)
         }          
            }).catch((err)=>console.log(err))
 }
 const task = useRef();
 useEffect(()=>{
  getRfqVendor()
 
 },[refresh])

 useEffect(()=>{
  setFilterDatas(ListData)
 
    if(ListData?.length>0){
      mergedProject()
      }

 },[ListData])


 useEffect(()=>{
  if(ListData?.length>0){
    mergedProject()
    }
 },[filterDatas])

  const filterData=(e)=>{
    setFilterDatas(ListData?.filter(item=>item?.po_no==e))
  }

  const filterQuotedRfq=()=>{
    setFilterDatas(ListData?.filter(item=>item?.quoted_price!==''))
  }

  const filterNewRfq=()=>{
    setFilterDatas(ListData?.filter(item=>item?.quoted_price==''))
  }
 
const mergedProject=()=>{
  setLoader(true)
  const map={};
  console.log(filterDatas,"u______________")
  filterDatas?.forEach(item=>{
    const key=item.rfq_no;
    if(!map[key]){
      map[key]=[];
    }
    map[key].push(item);
  })

const mergedData=[];
for(let key in map){
 
  if(map[key].length>1){
   
    const firstItem=map[key][0];

    const mergedItem={...firstItem};
    mergedItem.rowSpan=map[key].length;
    console.log(mergedItem,"lllll")

    mergedData.push(mergedItem);
    for(let i=1;i<map[key].length;i++){
      mergedData.push(map[key][i]);
    }
  }else{
    mergedData.push(...map[key])
  }
}
console.log('&&&&&&&&&&&&&&',mergedData)
setNewFilterData(mergedData)
setLoader(false)
}


const RefreshPage=()=>{
  getRfqVendor()
}

const setObjectsForEdit=(items)=>{
const aa=filterDatas.filter((item)=> item.rfq_no===items)
console.log(aa,"jjjjj")
  setSiderData(aa)
}


  return (
    <>
    <PageTitle activeMenu="Active RFQ" motherMenu="Dashboard" />

 <div className="row m-2">
           <div className="col-xl-12 wid-100">
    <Col lg={12}>
        <Link to={'/new-dashbord'}>
    <Badge className='mb-2' bg="primary">{`Back to Dashboard`}</Badge>
    </Link>
         <Card>
       
           <Card.Header>
             <Card.Title>Active RFQ</Card.Title>
             <div style={{display:'flex',gap:3}} className='col-6'>
             <div className='col-3 d-flex'>
              {/* <h8>Quanted RFQ List</h8> : */}
              
              <div style={{backgroundColor:'#C5E1A5' ,border:'solid black 1px' ,borderRadius:'5px',marginLeft:'2px',color:'black',padding:'5px',textAlign:'center'}} onClick={()=>filterQuotedRfq()}><h8 className='p-2' >Quoted RFQ List</h8></div>
</div>
<div className='col-3 d-flex'>
              {/* <h8>New RFQ List</h8> : */}
              {/* <div style={{width:'20px',height:'20px',backgroundColor:'#F9E4C6' ,border:'solid black 1px',borderRadius:'5px',marginLeft:'2px',padding:5}}></div> */}
              <div style={{backgroundColor:'#F9E4C6' ,border:'solid black 1px' ,borderRadius:'5px',marginLeft:'2px',color:'black',padding:'5px',textAlign:'center'}} onClick={()=>filterNewRfq()}><h8 className='p-2'>New RFQ List</h8></div>
</div>

             </div>
             <div className='col-3'>
             <Select   className="custom-react-select" 
                                           options={Array.from(new Set(searchBoxData)).map(option => ({
                                            value: option,
                                            label: option
                                        }))}
                                            name='country'
                                            onChange={(e)=>filterData(e.value)}
                                                isSearchable={false}                                             
                                            />
             </div>
            
           </Card.Header>
           <Card.Body className='ActiveTable'>
             <Table responsive striped>
               <thead>
                 <tr>
                   <th>#</th>
                   <th>RFQ No.</th>
                   <th>Sub Assembly</th>
                   <th>Description</th>
                   <th>Quoted Price</th>
                 </tr>
               </thead>
               <tbody >

               {!loader?newfilterData?.length &&newfilterData.map((item,index)=><tr>
                   <th style={{backgroundColor:item?.quoted_price!==''? '#C5E1A5':'#F9E4C6'}}>{index+1}</th>
                  {index===0||(index>0&& item.rfq_no!==newfilterData[index-1].rfq_no)? (<td rowSpan={item?.rowSpan||1} style={{color:'blue',backgroundColor:item?.quoted_price!==''?'#C5E1A5':'#F9E4C6'}} onClick={()=>{
                       
                        setObjectsForEdit(item.rfq_no)
                        task.current.showEmployeModal()
                        }}>{item?.rfq_no}</td>):null}
                   <td style={{backgroundColor:item?.quoted_price!==''?'#C5E1A5':'#F9E4C6'}}>
                    {item?.sub_assembly_name}
                   </td>
                  
                   <td style={{backgroundColor:item?.quoted_price!==''?'#C5E1A5':'#F9E4C6'}} >{item?.rfq_description}</td>
                   <td style={{backgroundColor:item?.quoted_price!==''?'#C5E1A5':'#F9E4C6'}}>{item?.quoted_price}</td>
                 </tr>
                ) :null}
               </tbody>
             </Table>
           </Card.Body>
         </Card>
       </Col>
       </div>
       </div>
     {  <ActiveRfqCanvas
           ref={task}
           updateSuccessfuly={()=>RefreshPage()}
                data={siderData}
                ></ActiveRfqCanvas>}
</>
  )
}

export default ActiveRfq