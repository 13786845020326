import React, { useEffect, useState } from 'react'
import MainPagetitle from '../../layouts/MainPagetitle'
import ProfileTopMenu from './ProfileTopMenu'

import Select from 'react-select';
import { Link } from 'react-router-dom';
import IMAGES from '../../../images/big/finance.jpg';
import { UpdateVendorProfile, getSegmentApi } from '../../../services/Profile/Profile';
import { Card, Table } from 'react-bootstrap';
import BusinessDropDown from './BusinessDropDown';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import { GetMainCategoryList } from '../../../services/RFQ/Rfq';

function BuisnessProfile({ data, submited = () => { } }) {
  // const [primaryCategory,setPrimaryCategory]=useState(data?.primary_category?.split(",")?data?.primary_category?.split(","):[])
  const status = useSelector(state => state.auth.auth.data.status);
  const options3 = [
    { value: 'Mr.', label: 'Mr.' },
    { value: 'Mrs.', label: 'Mrs.' },
    { value: 'Ms.', label: 'Ms.' },

  ]
  const options4 = [
    { value: 'proprietorship', label: 'proprietorship' },
    { value: 'partnership', label: 'partnership' },
    { value: 'private_ltd', label: 'private_ltd' },
    { value: 'LLP', label: 'LLP' }
  ]

  const firstNameList = data?.firstname?.split("|")
  const firstgenderList = data?.salutation?.split("|")
  const lastNameList = data?.lastname?.split("|")



  const [segmentList, setSegmentList] = useState([])
  const [tableData, setTableData] = useState(firstgenderList && firstgenderList?.length ? firstgenderList?.map((g, index) => ({
    gender: g,
    firstName: firstNameList[index],
    lastName: lastNameList[index]
  })) : [])
  const [constitution, setConstitution] = useState(options4.find((item) => item?.value === data?.partner_for) ? { value: data?.partner_for, label: data?.partner_for } : { value: 'proprietorship', label: 'proprietorship' });
  const [gender, setGender] = useState('Mr.')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('');
  const [infraFile, setInfraFile] = useState('');
  const [manufacture, setManufacture] = useState([]);
  const [man_power, setMan_power] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [other, setOther] = useState([])
  const [mainCategoryList,setMainCategoryList]=useState([]);
  const getSegmanet = async () => {
    await getSegmentApi().then((response) => {
      console.log('response.status', response.data.status)
      if (response.data) {
        // setListData(response?.data?.data?response?.data?.data:[])
        setSegmentList(response.data.data)
      }
    }).catch((err) => console.log(err))
  }
  const getMaincategoryLists=async ()=>{
    // setLoader(true)
    await GetMainCategoryList().then((response)=>{
         if(response.status==200){
            // setListData(response?.data?.data)
            // setSearchBox(response?.data.data.map((item)=>item?.po_no))
            // setLoader(false)
            setMainCategoryList(response.data.data)
         }          
            }).catch((err)=>console.log(err))
  }
  const categoryNameFilter=(AllDatas)=>{
    const ids=data?.primary_category?.split(',')
   return mainCategoryList?.map((item)=>{
      if(ids?.includes(item?.category_name)){
        return item?.category_name
      }else{
        return
      }
    }).filter((item)=>item!==undefined)
  }

  console.log('data?.primary_category',data?.primary_category)
  useEffect(() => {
    getSegmanet()
    getMaincategoryLists()
  }, [])
console.log('categoryNameFiltercategoryNameFilter',mainCategoryList,data?.primary_category?.split(','),categoryNameFilter())
  const onAdd = () => {
    setTableData([...tableData, { gender: gender, firstName: firstName, lastName: lastName }])
  }

  const onDelete = (indexs) => {
    const deletedArray = tableData.filter((_, index) => index !== indexs);

    setTableData(deletedArray)
  }
  console.log('LLLLLLLLLL', tableData)

  const getprofileData = async (data) => {
    await UpdateVendorProfile(data).then((response) => {
      if (response.status) {
        // setListData(response?.data?.data?response?.data?.data:[])
        console.log('::::::::::----::', response)
        submited('finishialDetail')
        swal("Done", "Update Successfully!", "success")
      }
    }).catch((err) => console.log(err))
  }
  const onsubmit = () => {
    const salutationFilter = tableData?.length ? tableData.map((item) => item.gender) : []
    const firstNameFilter = tableData?.length ? tableData.map((item) => item.firstName) : []
    const lastNameFilter = tableData?.length ? tableData.map((item) => item.lastName) : []

    let formData = new FormData();

    // Append simple text data
    formData.append('vendor_auto_id', data?._id);
    formData.append('organization_name', data?.organization_name);
    formData.append('email_id', data?.email_id);
    formData.append('mobile_number', data?.mobile_number);
    formData.append('pan_no', data?.pan_no);
    formData.append('coordination_person_name', data?.coordination_person_name);
    formData.append('designation', data?.designation);
    formData.append('country_code', data?.country_code);
    formData.append('country', data?.country);
    formData.append('primary_category', status ? data?.primary_category : primaryCategory.map(item => item.value).join(','));
    formData.append('partner_for', constitution.value);
    formData.append('salutation', salutationFilter?.join('|'));
    formData.append('firstname', firstNameFilter?.join('|'));
    formData.append('lastname', lastNameFilter?.join('|'));
    formData.append('username', data.username);
    formData.append('state', data?.state);
    formData.append('city', data?.city);
    formData.append('area', data?.area);
    formData.append('address', data?.address);
    formData.append('website', data?.website ? data?.website : '');
    formData.append('infrastructure[]', infraFile);
    formData.append('manufacture[]', manufacture);
    formData.append('other[]', other);
    formData.append('man_power[]', man_power);

    getprofileData(formData)
  }
  return (
    <div className="basic-form">

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-4" >
            <div className="clearfix">
              <div className="card card-bx profile-card author-profile m-b30">
                <div className="card-body">
                  <div className="p-5">
                    {/* <div className="author-profile">
                                                  <div className="author-media">
                                                      <img src={IMAGES.Tab1} alt="" />
                                                      <div className="upload-link" title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                                                          <input type="file" className="update-flie" />
                                                          <i className="fa fa-camera"></i>
                                                      </div>
                                                  </div>
                                                  
                                              </div> */}
                    <img src={IMAGES} alt="" className="w-100" style={{ borderRadius: "10px" }} />

                  </div>

                </div>

              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="card profile-card card-bx m-b30">
              {/* <div className="card-header">
                                  <h6 className="title">Account setup</h6>
                              </div> */}
              <form className="profile-form" onSubmit={(e) => {
                e.preventDefault()
                onsubmit()
              }}>
                <div className="card-body">
                  <div className="row">

                    <div className="col-sm-12 m-b30">
                      <label className="form-label">Constitution*</label>
                      <Select options={options4} className="custom-react-select"

                        defaultValue={constitution}
                        value={constitution}
                        isSearchable={false}
                        onChange={(e) => setConstitution(e)}
                      />
                    </div>
                    <label className="form-label">Enter Owner Name*</label>
                    <div className="row">

                      <div className='col-4'>

                        <Select options={options3} className="custom-react-select"
                          defaultValue={options3[0]}
                          isSearchable={false}
                          onChange={(e) => { setGender(e.value) }}

                        />
                      </div>
                      <div className='col-4'>

                        <input
                          required={!tableData?.length}
                          //  required={!constitution.value=='proprietorship'&&!tableData.length>0?true:false}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text" className="form-control" placeholder="Enter first name" defaultValue={''} />
                      </div>
                      <div className='col-4'>

                        <input
                          required={!tableData?.length}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text" className="form-control" placeholder="Enter Last name" defaultValue={''} />
                      </div>

                    </div>
                    <div className='col-6 mt-2'>
                      <button className="btn btn-primary" onClick={onAdd}>Add</button>
                    </div>
                    <div className='col-12'>



                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <strong></strong>
                            </th>
                            <th>
                              <strong></strong>
                            </th>
                            <th>
                              <strong></strong>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>

                          {tableData?.length && tableData?.map((item, index) => <tr>

                            <td>
                              <strong>{item.gender}</strong>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">

                                <span className="w-space-no">{item.firstName}</span>
                              </div>
                            </td>
                            <td>{item.lastName} </td>
                            <td> <div className="d-flex">


                              <i style={{ color: 'red' }} className="fa fa-trash" onClick={() => onDelete(index)}></i>

                            </div></td>

                          </tr>)}

                        </tbody>
                      </Table>


                    </div>
                    <div className="col-sm-12 m-b30">
                      <label className="form-label">Primary Category*</label>
                      {!data?.primary_category?.length ? <BusinessDropDown optionss={segmentList} onchange={(e) => { setPrimaryCategory(e) }} />
                        :
                        <input
                          disabled
                          type="text" className="form-control" placeholder="Enter Last Primary Category" defaultValue={categoryNameFilter()?.join(',')} />
                      }
                      {/* <input type="text" className="form-control"  /> */}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label d-flex">infrastructure (.xlsx)*
                        <h6 className='ml-3'> <a href='https://app-precismart.com/Infrastructure.xlsx' target='_blank'>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </a></h6>
                      </label>
                      {console.log('JJJJJJJJ', infraFile, data?.infrastructure ? true : false)}
                      <input accept=".xlsx" name='file' type="file" required={data?.infrastructure ? false : true} className="form-control" multiple onChange={(e) => {
                        setInfraFile(e.target.files[0])

                      }} />
                      {data?.infrastructure && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label d-flex">Machinery (.xlsx)
                        <h6 className='ml-3'> <a href='https://app-precismart.com/Machinery.xlsx' target='_blank'>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </a></h6>
                      </label>
                      <input accept=".xlsx" type="file" multiple className="form-control" onChange={(e) => setManufacture(e.target.files[0])} />
                      {data?.manufacture !== '' && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label d-flex">Man Power (.xlsx)
                        <h6 className='ml-3'> <a href='https://app-precismart.com/Man Power.xlsx' target='_blank'>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </a></h6>
                      </label>
                      <input accept=".xlsx" type="file" multiple className="form-control" onChange={(e) => setMan_power(e.target.files[0])} />
                      {data?.man_power !== '' && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">Others</label>
                      <input type="file" multiple className="form-control" onChange={(e) => setOther(e.target.files[0])} />
                      {data?.others !== '' && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 11.25V2.25" stroke="#362465" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>}
                    </div>


                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary" type='submit' >Submit</button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default BuisnessProfile