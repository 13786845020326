import React, { useState } from 'react'
import MainPagetitle from '../../layouts/MainPagetitle';
import HelpTopMenu from './HelpTopMenu';
import About from './About';
import Faqs from './Faqs';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';

function Help() {
    const [tab, setTab] = useState(1); 
  return (
    <>
 <MainPagetitle mainTitle="Helper"  parentTitle={'Helper'} />  
            <div className="container-fluid">
				<div className="row">
                <HelpTopMenu tabNumber={(tabNo)=>{setTab(tabNo)}}/>
                <div className="row">
          <div >
            {tab==1 &&<About/>}
            {tab==2 &&<Faqs/>}
            {tab==3 &&<TermsAndConditions/>}
            {tab==4 &&<PrivacyPolicy/>}
         
          </div>
        </div>
                </div>
                </div>

    </>
  )
}

export default Help