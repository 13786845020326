import React, { useEffect, useReducer, useState } from 'react'
import MainPagetitle from '../../layouts/MainPagetitle'
import CardWidget from './elements/CardWidget'
import ProjectOverviewTab from './elements/ProjectOverviewTab'
import ProjectStatusBlogDonutChart from './elements/ProjectStatusBlogDonutChart'
import { SVGICON } from '../../constant/theme'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom'
import { GetCustomerDashboardCount, GetProfileCompleteCount, GetVendorDashboardCount } from '../../../services/Dashbord/Dashbord'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
// import { store } from '../../../store/store'
import imagelogo from '../../../images/background/logo-full.png'
import { string } from 'yup'
import { ProgressBar } from 'react-bootstrap'
import './elements/style.css'
import './style.css'
function NewDashBord() {
    const [profileCompleted,setProfileCompleted]=useState()
    const [dashbordCount,setDashbordCount]=useState({})
    const id = useSelector(state => state.auth.auth.data.id);
    const userType = useSelector(state => state.auth.auth.data.user_type);	
console.log('userTypeuserType',userType)
    const counterBlog = [
        { title: 'Awarded', count : dashbordCount?.awarded_count, colorstyle: 'success', icon:  <i className="la la-graduation-cap" style={{fontSize:'-webkit-xxx-large',marginTop:'10px',color:'orange'}}></i>  },
        { title: 'Quoted', count : dashbordCount?.quoted_count, colorstyle: 'primary', icon: SVGICON.Progress  },
        { title: 'Regretted', count :dashbordCount?.regret_count, colorstyle: 'purple', icon: SVGICON.Started},
        { title: 'Completed', count : dashbordCount?.completed_count, colorstyle: 'danger', icon: SVGICON.Canceled},
        { title: 'Unawarded', count :dashbordCount?.ignored_count, colorstyle: 'danger', icon: SVGICON.Started},
    ];
 
    console.log('profileCompleted',profileCompleted)
     

const getprofileDataVendor=async ()=>{
   await GetProfileCompleteCount(id).then((response)=>{
        if(response.status){
           setProfileCompleted(response.data.profile_completion_percentage)
        }          
           }).catch((err)=>console.log(err))
}
const getDashoboardData=async ()=>{
    await GetVendorDashboardCount(id).then((response)=>{
         if(response.status){
            // setProfileCompleted(response.data.profile_completion_percentage)
             setDashbordCount(response.data.count_data[0])
         }
                
            }).catch((err)=>console.log(err))
 }


 const getDashoboardDataCountCustomer=async ()=>{
    await GetCustomerDashboardCount(id).then((response)=>{
         if(response.status){
            // setProfileCompleted(response.data.profile_completion_percentage)
            setProfileCompleted(response.data.customer_profile_completion_percentage)
         }
                
            }).catch((err)=>console.log(err))
 }



    useEffect(()=>{  
      if(userType!=="Customer"){
        getprofileDataVendor();
        getDashoboardData()
      }else{
        getDashoboardDataCountCustomer()
      }
    },[])


  return (
    <>
    <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home" /><div className="container-fluid">
          <div className="row">
              <div className="col-xl-12 wid-100">
                  <div className="row d-flex">
                    <div className='col d-flex col-12 container justify-content-center'>
                  <div className="card col-6 mb-4">
                  <Link to={'/profile'}>
                  <div className="card-body removeTitle">
                { profileCompleted? <ProjectStatusBlogDonutChart value={profileCompleted} img={imagelogo}/>:<ProjectStatusBlogDonutChart value={0} img={imagelogo}/>}
                  <div className="project-date">       
                            <div className="project-media" key={1} style={{textAlign:'center'}}>            
                                <h5 style={{width:"100%",textAlign:'center',fontWeight:'bold'}}> My Profile</h5>
                            </div>	                      
                    </div>
                  </div>
                  </Link>
                  </div>
                 {userType!=="Customer"&& <div className="card col-6 mb-4">
                    <Link to={'/so-traking'}>
                  <div className="card-body">
                  <ProjectStatusBlogDonutChart value={dashbordCount?.ongoing_count?dashbordCount?.ongoing_count:0} title={dashbordCount?.ongoing_count?String(dashbordCount?.ongoing_count):String(0)}/>
                  <div className="project-date">       
                            <div className="project-media" key={1} style={{textAlign:'center'}}>            
                                <h5 style={{width:"100%",textAlign:'center',fontWeight:'bold'}}> SO Tracking</h5>
                            </div>	                      
                    </div>
                  </div>
                  </Link>
                  </div>}
                  </div>
                      {/* <CardWidget /> */}
                     


                      <div className="row d-flex justify-content-center">
                      {userType!=="Customer"&& counterBlog.map((data, index)=>(                        
                        <div className="col-xl-2 col-sm-6 col-lg-6" key={index}>
                            <div className="card box-hover">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className={`icon-box icon-box-lg  rounded-circle bg-${data.colorstyle}-light `}>
                                           {data.icon}
                                        </div>
                                        <div className="total-projects ms-3">                                        
                                            <CountUp  className={`project-counter count text-${data.colorstyle}`} end={data.count} duration="5"/>                                            
                                            <h6>Total {data.title}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                      </div>
                      {/* <div className="col-xl-4">
                          <ToDoList />
                      </div> */}
                      <div className="row d-flex" style={{justifyContent:'center'}}>

                        <div className='col-12 d-flex mb-5' >
                            <div className='row w-100'>

                            
                      <div className='col-5' > <hr style={{color:'black'}}></hr></div>
                    <h6 className='col-2 text-center text-dark'>RFQ Reports</h6>
                    <div className='col-5' > <hr style={{color:'black'}}></hr></div>
                      </div>
                      </div>
                      <div className="" >
                        
                 { userType!=="Customer"? <div className="c-work">
                
                        <Link to={'/active-rfq'}>
                        <div className="card box-hover shadow" style={{width:'170px' }}>
                                <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className="d-flex align-items-center" >
										<h6 >Active RFQ 
                                        <div className="custom-progress-bar progress-cst" style={{height:5}}>
                                   
                                            <ProgressBar now={100} variant="purple" className='mt-1'/>
                                            </div>
                                            </h6>
										{/* <span>$50,585</span> */}
									</div>
                                    </div>
                                    </div>
                                    </Link>
                                    <Link to={'/awarded-rfq'}>
                                    <div className="card box-hover shadow" style={{width:'170px',textAlign: 'center' }}>
                                <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className="d-flex align-items-center">
                                       <h6>Awarded RFQ 
                                       <div className="custom-progress-bar progress-cst" style={{height:5}}>
                                   <ProgressBar now={100} variant="purple" className='mt-1'/>
                                   </div>
                                       </h6>
										
                                       
										{/* <span>415</span> */}
									</div>
                                    </div>
                                    </div>
                                    </Link>
                                    <Link to={'/so-traking'}>
                                    <div className="card box-hover shadow" style={{width:'170px',textAlign: 'center' }}>
                                <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className="d-flex align-items-center">
										<h6>SO Tracking
                                        <div className="custom-progress-bar progress-cst" style={{height:5}}>  
                                   <ProgressBar now={100} variant="purple" className='mt-1'/>
                                   </div>
                                        </h6>
										{/* <span>200</span> */}
									</div>
                                    </div>
                                    </div>
                                    </Link>
                                    <Link to={'/completed-rfq'}>
                                    <div className="card box-hover shadow" style={{width:'170px',textAlign: 'center' }}>
                                <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className="d-flex align-items-center">
										<h6>Completed RFQ 
                                        <div className="custom-progress-bar progress-cst" style={{height:5}}>           
                                   <ProgressBar now={100} variant="purple" className='mt-1'/>
                                   </div>
                                        </h6>
										{/* <span>$50,585</span> */}
									</div>
                                    </div>
                                    </div>
                                    </Link>
                                    <Link to={'/unawarded-rfq'}>
                                    <div className="card box-hover shadow" style={{width:'170px',textAlign: 'center' }}>
                                <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className="d-flex align-items-center">
										<h6>Unawarded RFQ 
                                        <div className="custom-progress-bar progress-cst" style={{height:5}}>                                 
                                   <ProgressBar now={100} variant="purple" className='mt-1'/>
                                   </div>
                                        </h6>
										{/* <span>415</span> */}
                                        </div>
                                        </div>
									</div>
                                    </Link>
                                    <Link to={'/regretted-rfq'}>
                                    <div className="card box-hover shadow" style={{width:'170px',textAlign: 'center' }}>
                                <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className="d-flex align-items-center">
										<h6>Regretted RFQ 
                                        <div className="custom-progress-bar progress-cst" style={{height:5}}>                                 
                                   <ProgressBar now={100} variant="purple" className='mt-1'/>
                                   </div>
                                        </h6>
										{/* <span>200</span> */}
									</div>
                                    </div>
                                    </div>
                                    </Link>
								</div>:  <div className="c-work"><Link to={'/project-tracking'}>
                                <div className="card box-hover shadow" style={{width:'170px',textAlign: 'center' }}>
                                <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className="d-flex align-items-center">
										<h6 >Project Tracking
                                        <div className="custom-progress-bar progress-cst" style={{height:5}}>                                 
                                   <ProgressBar now={100} variant="purple" className='mt-1'/>
                                   </div>
                                        </h6>
										{/* <span>$50,585</span> */}
									</div>
                                    </div>
                                    </div>
                                    </Link>
                                    </div>
                                }
</div>
                                </div>



                  </div>
              </div>
          </div>
      </div></>
                
  )
}

export default NewDashBord