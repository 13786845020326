import React, { useEffect, useRef, useState } from 'react'
import { Badge, Card, Dropdown, ProgressBar, Table } from 'react-bootstrap'
import PageTitle from '../../../layouts/PageTitle'
import Select from 'react-select';
import { useSelector } from 'react-redux'
import ProjectRejectionCanvas from '../../ProjectRejection/ProjectRejectionCanvas'
import image from '../../../../images/time.png'
import swal from 'sweetalert'
import { GetCustomerProjectDropdown, GetCustomerSubass, getCustomerRemark, setCustomerRemark } from '../../../../services/Dashbord/Dashbord'

function ProjectReject() {
    const id = useSelector(state => state.auth.auth.data.id);
    const options = {
        chart: {
          type: "donut",
          width: 250,
        },
        colors: ["#3AC977", "grey", "var(--primary)", "#FF5E5E"],
        labels: ["Compete", "Pending", "Yet to Start", "Cancelled"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "90%",
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: 12,
                },
                value: {
                  show: true,
                  fontSize: "24px",
                  fontFamily: "Arial",
                  fontWeight: "500",
                  offsetY: -17,
                },
                total: {
                  show:  true,
                  fontSize: "11px",
                  fontWeight: "500",
                  fontFamily: "Arial",
                  label: "Total projects",
                  formatter: function (w) {
                    return w?.globals?.seriesTotals?.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      };
      const task = useRef();
const [dropDown,setDropDown]=useState([])
const [tableData,setTableData]=useState([])
const [remarkValues, setRemarkValues] = useState({});
const [remarkList,setRemarkList]=useState([]);
const [images,setImages]=useState([]);
const [title,setTitle]=useState('')

const getDropDown=async ()=>{
    await GetCustomerProjectDropdown(id).then((response)=>{
        if(response.status){
            const dataArray = response?.data?.data;
            setDropDown(dataArray)
        }          
    }).catch((err)=>console.log(err))
}

useEffect(()=>{
    getDropDown()
},[])

const onRemarkSubmit=async (item, index)=>{
  console.log('remarkValues[index]',remarkValues[index])
  if(remarkValues[index]&&remarkValues[index] !== ''){
    const postData = {    
      "po_no": item.po_no,
      "sub_assembly_name": item.name,
      "customer_remark": remarkValues[index],       
    }
    await setCustomerRemark(postData).then((response)=>{
        if(response.status){
          swal("Remark is updated!.");
          setRemarkValues((prevValues) => ({ ...prevValues, [index]: '' }));
        }          
    }).catch((err)=>console.log(err))
  }else{
    swal("please enter remark.");
  }
}

const getRemarkData=async (item)=>{
    const postData={    
        "po_no": item.po_no,
        "sub_assembly_name": item.name,
    }
    await getCustomerRemark(postData).then((response)=>{
        if(response.status){
            setRemarkList(response.data.data)
        }          
    }).catch((err)=>console.log(err))
}

const getSubData=async (pno)=>{
    setTableData([])
    await GetCustomerSubass(id, pno).then((response)=>{
        if(response.status){
            setTableData(response?.data?.data.Sub_Assembly)
        } else {
            setTableData([])
        }       
    }).catch((err)=>console.log(err))
}

function calculateRowMaterialSum(data) {
    let sum = 0;
    for (let item of data) {
        if (item.averages && item.averages?.['Raw Material']) {
            sum += item.averages?.['Raw Material'];
        }
    }
    if(data?.length){
      const divide=data.map((item)=> item.averages);
      const divideBy=divide.filter((item)=> item?.['Raw Material']>0)?.length
    //   return sum/divideBy;
      if (divideBy > 0) {
        return sum / divideBy;
    } else {
        return 0;
    }
    }else{
      return 0
    }
}

function calculateInspectionSum(data) {
    let sum = 0;
    for (let item of data) {
        if (item.averages && item.averages.Inspection) {
            sum += item.averages.Inspection;
        }
    }
    if(data?.length){
      const divide=data.map((item)=> item.averages);
      const divideBy=divide.filter((item)=> item?.['Inspection']>0)?.length
    //   return sum/divideBy;
    if (divideBy > 0) {
        return sum / divideBy;
    } else {
        return 0;
    }
    }else{
      return 0
    }
}

function calculateMachiningSum(data) {
    let sum = 0;

    for (let item of data) {
        if (item?.averages && item?.averages?.['Machining/Fabrication']) {
            sum += item?.averages?.['Machining/Fabrication'];
        }
    }

    if (data?.length) {
        const divide = data?.map((item) => item?.averages);
        const divideBy = divide?.filter((item) => item?.['Machining/Fabrication'] > 0)?.length;

        if (divideBy > 0) {
            return sum / divideBy;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}


function calculateAssemblySum(data) {
    let sum = 0;
    for (let item of data) {
        if (item.averages && item.averages.Assembly) {
            sum += item.averages.Assembly;
        }
    }
    if(data?.length){
      const divide=data.map((item)=> item.averages);
      const divideBy=divide.filter((item)=> item?.['Assembly']>0)?.length
    //   return sum/divideBy;
    if (divideBy > 0) {
        return sum / divideBy;
    } else {
        return 0;
    }
    }else{
      return 0
    }
}

function calculatePackagingSum(data) {
    let sum = 0;
    for (let item of data) {
        if (item.averages && item.averages.Packaging) {
            sum += item.averages.Packaging;
        }
    }
    if(data?.length){
      const divide=data.map((item)=> item.averages);
      const divideBy=divide.filter((item)=> item?.['Packaging']>0)?.length
    //   return sum/divideBy;
    if (divideBy > 0) {
        return sum / divideBy;
    } else {
        return 0;
    }
    }else{
      return 0
    }
}

function calculateDispatchSum(data) {
    let sum = 0;
    for (let item of data) {
        if (item.averages && item.averages.Dispatch) {
            sum += item.averages.Dispatch;
        }
    }
    if(data?.length){
      const divide=data.map((item)=> item.averages);
      const divideBy=divide.filter((item)=> item?.['Dispatch']>0)?.length
    //   return sum/divideBy;
    if (divideBy > 0) {
        return sum / divideBy;
    } else {
        return 0;
    }
    }else{
      return 0
    }
}
console.log('dropDown',dropDown)
return (
    <div>
        <PageTitle activeMenu="Project Tracking" motherMenu="Dashboard" />
        <div className="row m-2">
            <Card>
                <Card.Header>
                    <Card.Title>Project Tracking</Card.Title>
                </Card.Header>
                <Select
                    className="custom-react-select" 
                    options={ Object?.entries(dropDown).map(([key, value]) => ({
                        value: value,
                        label: value
                    }))}
                    name='country'
                    onChange={(e)=>{getSubData(e.value)}}
                    isSearchable={false}
                />
                <Card.Body>
                    <Table responsive bordered>
                        <thead>
                            <tr>
                                <th><p>sr.No</p></th>
                                <th><p>Sub Assembly</p></th>
                                <th><p>Part Desc.</p></th>
                                <th><p>Date</p></th>
                                <th style={{textAlign:'center'}}>
                                    Raw Material
                                    <div>
                                        <div style={{textAlign:'center',color:'blue'}} >
                                            <small style={{color:'orange'}}>{calculateRowMaterialSum(tableData)}%</small>
                                            <ProgressBar now={calculateRowMaterialSum(tableData)} variant="success" />
                                        </div>
                                    </div>
                                </th>
                                <th style={{textAlign:'center'}}>
                                    inspection
                                    <div>
                                        <div style={{textAlign:'center',color:'blue'}} >
                                            <small style={{color:'orange'}}>{calculateInspectionSum(tableData)}%</small>
                                            <ProgressBar now={calculateInspectionSum(tableData)} variant="success" />
                                        </div>
                                    </div>
                                </th>
                                <th style={{textAlign:'center'}}>
                                    Machining./Fabrication
                                    <div>
                                        <div style={{textAlign:'center',color:'blue'}} >
                                            <small style={{color:'orange'}}>{calculateMachiningSum(tableData)}%</small>
                                            <ProgressBar now={calculateMachiningSum(tableData)} variant="success" />
                                        </div>
                                    </div>
                                </th>
                                <th style={{textAlign:'center'}}>
                                    Assembly
                                    <div>
                                        <div style={{textAlign:'center',color:'blue'}} >
                                            <small style={{color:'orange'}}>{calculateAssemblySum(tableData)}%</small>
                                            <ProgressBar now={calculateAssemblySum(tableData)} variant="success" />
                                        </div>
                                    </div>
                                </th>
                                <th style={{textAlign:'center'}}>
                                    Packaging
                                    <div>
                                        <div style={{textAlign:'center',color:'blue'}} >
                                            <small style={{color:'orange'}}>{calculatePackagingSum(tableData)}%</small>
                                            <ProgressBar now={calculatePackagingSum(tableData)} variant="success" />
                                        </div>
                                    </div>
                                </th>
                                <th style={{textAlign:'center'}}>
                                    Dispatch
                                    <div>
                                        <div style={{textAlign:'center',color:'blue'}} >
                                            <small style={{color:'orange'}}>{calculateDispatchSum(tableData)}%</small>
                                            <ProgressBar now={calculateDispatchSum(tableData)} variant="success" />
                                        </div>
                                    </div>
                                </th>
                                <th><p>Remarks</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.length && tableData?.map((item, index) => (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <td>{item.name}</td>
                                    <td>{item.part_desc}</td>
                                    <td>{item.date[0]}</td>
                                    <td style={{textAlign:'center',color:'blue'}}>
                                        <small>{item?.averages?.['Raw Material'] ? item?.averages?.['Raw Material'] : 0}%</small>
                                        <ProgressBar now={item?.averages?.['Raw Material'] ? item?.averages?.['Raw Material'] : 0} variant="primary" />
                                        <i style={{color:'black'}} className="fa-regular fa-eye ml-2"
                                            onClick={() => {
                                                setTitle('Row Material')
                                                setImages(item?.groupedData?.['Raw Material']?.map((item) => item?.process_image))
                                                task.current.showEmployeModal()
                                            }}
                                        ></i>
                                    </td>
                                    <td style={{textAlign:'center',color:'blue'}}>
                                        <small>{item.averages?.Inspection ? item.averages?.Inspection : 0}%</small>
                                        <ProgressBar now={item.averages?.Inspection ? item.averages?.Inspection : 0} variant="primary" />
                                        <i style={{color:'black'}} className="fa-regular fa-eye ml-2"
                                            onClick={() => {
                                                setTitle('Inspection')
                                                setImages(item?.groupedData?.Inspection?.map((item) => item?.process_image))
                                                task.current.showEmployeModal()
                                            }}
                                        ></i>
                                    </td>
                                    <td style={{textAlign:'center',color:'blue'}}>
                                        <small>{item.averages['Machining/Fabrication'] ? item.averages['Machining/Fabrication'] : 0}%</small>
                                        <ProgressBar now={item.averages?.['Machining/Fabrication'] ? item.averages['Machining/Fabrication'] : 0} variant="primary" />
                                        <i style={{color:'black'}} className="fa-regular fa-eye ml-2"
                                            onClick={() => {
                                                setTitle('Machining/Fabrication')
                                                setImages(item?.groupedData?.['Machining/Fabrication']?.map((item) => item?.process_image))
                                                task.current.showEmployeModal()
                                            }}
                                        ></i>
                                    </td>
                                    <td style={{textAlign:'center',color:'blue'}}>
                                        <small>{item.averages.Assembly ? item.averages.Assembly : 0}%</small>
                                        <ProgressBar now={item.averages.Assembly ? item.averages.Assembly : 0} variant="primary" />
                                        <i style={{color:'black'}} className="fa-regular fa-eye ml-2"
                                            onClick={() => {
                                                setTitle('Assembly')
                                                setImages(item?.groupedData?.Assembly?.map((item) => item?.process_image))
                                                task.current.showEmployeModal()
                                            }}
                                        ></i>
                                    </td>
                                    <td style={{textAlign:'center',color:'blue'}}>
                                        <small>{item.averages.Packaging ? item.averages.Packaging : 0}%</small>
                                        <ProgressBar now={item.averages.Packaging ? item.averages.Packaging : 0} variant="primary" />
                                        <i style={{color:'black'}} className="fa-regular fa-eye ml-2"
                                            onClick={() => {
                                                setTitle('Packaging')
                                                setImages(item?.groupedData?.Packaging?.map((item) => item?.process_image))
                                                task.current.showEmployeModal()
                                            }}
                                        ></i>
                                    </td>
                                    <td style={{textAlign:'center',color:'blue'}}>
                                        <small>{item.averages.Dispatch ? item.averages.Dispatch : 0}%</small>
                                        <ProgressBar now={item.averages.Dispatch ? item.averages.Dispatch : 0} variant="primary" />
                                        <i style={{color:'black'}} className="fa-regular fa-eye ml-2"
                                            onClick={() => {
                                                setTitle('Dispatch')
                                                setImages(item?.groupedData?.Dispatch?.map((item) => item?.process_image))
                                                task.current.showEmployeModal()
                                            }}
                                        ></i>
                                    </td>
                                    <td style={{textAlign:'center',color:'blue'}}>
                                        <input
                                            type="text"
                                            value={remarkValues[index] || ''}
                                            className="form-control"
                                            placeholder='Enter Remark...'
                                            onChange={(e) => setRemarkValues({ ...remarkValues, [index]: e.target.value })}
                                        />
                                        <div className="row col-12 m-auto">
                                            <Badge
                                                bg=""
                                                className="badge-primary light mt-2 col-6"
                                                style={{textAlign:'center',paddingTop:'10px'}}
                                                onClick={() => onRemarkSubmit(item, index)}
                                            >
                                                Submit
                                            </Badge>
                                            <Dropdown className="dropdown col-6" onClick={() => getRemarkData(item)}>
                                                <Dropdown.Toggle
                                                    variant="primary light"
                                                    className="i-false p-0 sharp mt-2"
                                                >
                                                    <img src={image} alt='' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu">
                                                    {remarkList?.length && remarkList.map((remarkItem, remarkIndex) => (
                                                        <Dropdown.Item
                                                            className="dropdown-item"
                                                            to="/widget-basic"
                                                            key={remarkIndex}
                                                        >
                                                            Remark {remarkIndex + 1}: <span className='text-primary'>{remarkItem?.customer_remark}</span>(<span className='text-success'>{remarkItem?.date}</span>)
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> 
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
        <ProjectRejectionCanvas
            ref={task}
            data={images}
            title={title}
        />
    </div>
  )
}

export default ProjectReject
