import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function HelpTopMenu({tabNumber=()=>{}}) {

    const [tab,setTab]=useState(1)
    useEffect(()=>{
 tabNumber(tab)
    },[tab])
  return (
    <ul className="nav nav-tabs style-1 mb-4" tab="pills-tab" role="tablist">
     <li className="nav-item" onClick={()=>setTab(1)}>
        <Link  className={`nav-link ${tab === 1 ? 'active' : ''}`}>About Us</Link>
    </li>
    <li className="nav-item" onClick={()=>setTab(2)}>
        <Link   className={`nav-link  ${tab === 2 ? 'active' : ''}`}>FAQs</Link>
    </li>
    <li className="nav-item" onClick={()=>setTab(3)}> 
        <Link  className={`nav-link ${tab === 3 ? 'active' : ''}`}>Terms And Conditions</Link>
    </li>
    <li className="nav-item" onClick={()=>setTab(4)}>
        <Link className={`nav-link ${tab === 4 ? 'active' : ''}`}>Privacy Policy</Link>
    </li>

   
</ul>
  )
}

export default HelpTopMenu