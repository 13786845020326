import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Dropdown, Offcanvas, ProgressBar, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import DonutChart from '../../pages/WidgetBasic/DonutChart';
import { GetAllProcessData, GetSoTrakingRemarkList, ShowAllProcessData, addSoTrakingRemarkList, submitCompleteSoTraking } from '../../../services/RFQ/Rfq';
import { useSelector } from 'react-redux';
import SoImageShower from './SoImageShower';
// import MyImage from "../images/iconly/Bulk/Download.svg";
import image from '../../../images/time.png'
const SoTrakingCanvas = forwardRef((props, ref) => {
  const [addEmploye, setAddEmploye] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [viewData, setViewData] = useState('');
  const [remarkList, setRemarkList] = useState([])
  const [remark, setRemark] = useState('')
  const [images, setImages] = useState([]);
  const tableRef = useRef(null);
  const completedSO = tableData.find((item) => item.process_completion_percentage !== "100")
  console.log('completedSO', completedSO)
  const profile = useSelector(state => state.auth.auth.data);
  let AllData = props.data;
  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true)
    }
  }));
  const nav = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    nav("#");
  }
  console.log('profileprofile', tableData)

  const GetAllProcessDat = async () => {
    const data = {
      vendor_auto_id: profile.id,
      rfq_auto_id: AllData?._id,
      vendor_type: 'original',

    }
    await GetAllProcessData(data).then((response) => {
      if (response.status) {
        // setListData(response?.data?.data?response?.data?.data:[])
        console.log('response.data[0]', response.data.data[0].process_details)
        setTableData(response.data.data[0].process_details)
      }
    }).catch((err) => console.log(err))
  }
  const getSORemark = async () => {

    await GetSoTrakingRemarkList(AllData?.po_no, AllData?.sub_assembly_name,).then((response) => {
      if (response.status) {
        // setListData(response?.data?.data?response?.data?.data:[])
        console.log('response.data[0]', response.data.data)
        setRemarkList(response?.data.data.map((item) => item))
        //  setRemarkList(response.data.data[0].process_details)
      }
    }).catch((err) => console.log(err))
  }
  const handleDownloadClick = (e,url) => {
    e.preventDefault();
    window.open(`https://app-precismart.com/images/RFQ/${url}`, '_blank');
  };


  useEffect(() => {
    getSORemark()
    GetAllProcessDat()
  }, [props, remark])


  useEffect(() => {
    showImages()
  }, [viewData])


  const showImages = async () => {
    console.log('viewDataviewData', viewData)
    const data = {
      vendor_auto_id: profile.id,
      rfq_auto_id: AllData?._id,
      vendor_type: 'original',
      process_name: viewData,
    }
    await ShowAllProcessData(data).then((response) => {
      if (response.status) {
        // setListData(response?.data?.data?response?.data?.data:[])
        console.log('response.data[0]', response.data.data)
        setImages(response.data.allTrackProject)
        //  setTableData(response.data.data[0].process_details)
      }
    }).catch((err) => console.log(err))
  }


  console.log('AllDataAllData', AllData)
  const onClickRemarkSubmit = async () => {
    if (remark !== '') {

      await addSoTrakingRemarkList(AllData?.po_no, AllData?.sub_assembly_name, remark).then((response) => {
        if (response.status) {
          swal("Remark is submitted");
          setRemark('')
          // setRemarkList()

        }
      }).catch((err) => console.log(err))
    } else {
      swal("Please Enter Remark");
    }
  }

  const onComplete = async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to Complete ?",
      icon: "success",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // updateStatus(masterId,_id,'Reject')
        setAddEmploye(false)
        submitCompleteSoTraking(AllData.vendor_auto_id, AllData._id, 'Completed').then((response) => {
          if (response.status) {

            setRemark('')
            // setRemarkList()
          }
        }).catch((err) => console.log(err))
        swal("You completed it!", {
          icon: "success",
        });

      } else {
        swal("Not completed!");
        setAddEmploye(false)
      }
    })

  }


  return (
    <>
      <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
        <div className="offcanvas-header">
          <h5 className="modal-title" id="#gridSystemModal">{props?.title ? props?.title : 'SO Tracking'}</h5>
          <button type="button" className="btn-close"
            onClick={() => setAddEmploye(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div className="offcanvas-body">
          <div className='row col-6'>
            <div className='col-7 mt-2'>
              <label>SO NO. : <span className='text-primary'>{AllData?.so_number}</span>

              </label>
            </div>
            <div className='col-3'>
              {/* {
                          <Badge bg="" 
                         className={props.completeButton&& tableData?.length&&tableData[0]?.project_details?.length&&tableData[0]?.project_details?.[0]?.admin_assembly_completed==1?  "badge-success light":"badge-secondary light"} 
                         onClick={()=>props.completeButton&&tableData?.length&&tableData[0]?.project_details?.length&&tableData[0]?.project_details?.[0]?.admin_assembly_completed==1&&onComplete()}
                         >Complete</Badge>} */}
            </div>
            <div className='col-2'>
  <Dropdown className="dropdown">
    <Dropdown.Toggle variant="primary light" className=" i-false p-0 sharp">
      <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <circle fill="#000000" cx="5" cy="12" r="2" />
          <circle fill="#000000" cx="12" cy="12" r="2" />
          <circle fill="#000000" cx="19" cy="12" r="2" />
        </g>
      </svg>
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu">
      <Dropdown.Item className="dropdown-item" to="/widget-basic">
        Project No :<span className='text-primary'>{AllData?.po_no}</span>
      </Dropdown.Item>
      <Dropdown.Item className="dropdown-item" to="/widget-basic">
        Project Date : :<span className='text-primary'>{AllData?.rfq_date}</span>
      </Dropdown.Item>
      <Dropdown.Item className="dropdown-item" to="/widget-basic">
        Description : <span className='text-primary'>{AllData?.rfq_type}</span>
      </Dropdown.Item>
      <Dropdown.Item className="dropdown-item" to="/widget-basic">
        Work Completion Date:<span className='text-primary'>{AllData?.completion_date}</span>
      </Dropdown.Item>
      <Dropdown.Item className="dropdown-item" to="/widget-basic">
        Weight:<span className='text-primary'>{AllData?.weight}</span>
      </Dropdown.Item>
      <Dropdown.Item className="dropdown-item" to="/widget-basic">
        Quantity/Unit:<span className='text-primary'>{AllData?.quantity}/{AllData?.unit_of_measurment}</span>
      </Dropdown.Item>
      <Dropdown.Item className="dropdown-item" to="/widget-basic">
        PO Document:<span className='text-primary'>
        <a href="#" onClick={(e)=>handleDownloadClick(e,AllData.po_document)}>
          <div onClick={() => console.log('</div></div>')}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.1221 15.4361L12.1221 3.39508" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.0381 12.5084L12.1221 15.4364L9.20609 12.5084" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16.7551 8.12799H17.6881C19.7231 8.12799 21.3721 9.77699 21.3721 11.813V16.697C21.3721 18.727 19.7271 20.372 17.6971 20.372L6.55707 20.372C4.52207 20.372 2.87207 18.722 2.87207 16.687V11.802C2.87207 9.77299 4.51807 8.12799 6.54707 8.12799L7.48907 8.12799" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          </a>
        </span>
      </Dropdown.Item>
      {/* <Dropdown.Item className="dropdown-item" to="/widget-basic">
        <a href="#" onClick={(e)=>handleDownloadClick(e,AllData)} target='_blank' >
          RFQ Document:<span className='text-primary'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.1221 15.4361L12.1221 3.39508" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.0381 12.5084L12.1221 15.4364L9.20609 12.5084" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16.7551 8.12799H17.6881C19.7231 8.12799 21.3721 9.77699 21.3721 11.813V16.697C21.3721 18.727 19.7271 20.372 17.6971 20.372L6.55707 20.372C4.52207 20.372 2.87207 18.722 2.87207 16.687V11.802C2.87207 9.77299 4.51807 8.12799 6.54707 8.12799L7.48907 8.12799" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>  
        </a>
      </Dropdown.Item> */}
    </Dropdown.Menu>
  </Dropdown>
</div>

          </div>
          <div className="">
            {!props?.title &&
              <div className='d-flex mb-2 ' style={{ gap: 2 }}>
                <div className='mr-4' style={{ marginRight: '102px' }}>
                  <h5 className="modal-title mr-2" id="#gridSystemModal">{'SO Completion Status: '}</h5>
                </div>
                <div>
                  <Badge bg=""
                    className={props.completeButton && tableData?.length && tableData[0]?.project_details?.length && tableData[0]?.project_details?.[0]?.admin_assembly_completed == 1 ? "badge-primary " : "badge-secondary light"}
                    onClick={() => props.completeButton && tableData?.length && tableData[0]?.project_details?.length && tableData[0]?.project_details?.[0]?.admin_assembly_completed == 1 && onComplete()}
                  >Complete</Badge>
                </div>
              </div>
            }
            <Table responsive bordered className="verticle-middle">
              <thead>
                <tr>
                  <th scope="col">Process Name</th>
                  <th scope="col">% Completed</th>
                  <th scope="col">Upload Photo</th>
                  <th scope="col">No.of Photos</th>
                  <th scope="col">Last Updated Date</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>

                {tableData.length && tableData.map((item) => <tr>
                  <td>{item.process_names}</td>
                  <td style={{ textAlign: 'center', color: 'blue' }}>
                    <small>{item.process_completion_percentage}%</small>
                    <ProgressBar now={Number(item.process_completion_percentage)} variant="primary" />



                  </td>
                  <td onClick={() => {
                    console.log('item.process_names', item.process_names)
                    setViewData(item.process_names)
                    setTimeout(() => {
                      const showImgId = document.getElementById('showImg');
                      showImgId.scrollIntoView({ behavior: 'smooth' });
                    }, 1000)

                  }}><Badge bg="" className="badge-primary light">View</Badge></td>
                  <td style={{ textAlign: 'center' }}>
                    <h6>{item.project_details.length}</h6>
                  </td>
                  <td>
                    {item.last_updated_date}

                  </td>
                  <td>Pending</td>
                </tr>
                )
                }



              </tbody>
            </Table>
            {!props?.title && <div className="col-sm-6 mb-3">

              <label className="form-label d-flex"> <a href={`https://app-precismart.com/images/project_track/C://fakepath//img_1011-1.jpg`} download="RFQ_Document">Subassembly Remarks:</a>

                {remarkList.length && <Dropdown className="dropdown">
                  <Dropdown.Toggle
                    variant="primary light"
                    className=" i-false p-0 sharp"
                  >
                    <img src={image} alt='' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    {remarkList.map((item, index) => <Dropdown.Item
                      className="dropdown-item"
                      to="/widget-basic"
                    >
                      Remark {index + 1} : <span className='text-primary'>{item?.remark_status}</span> (<span className='text-success'>{item?.date}</span> )
                    </Dropdown.Item>)}
                  </Dropdown.Menu>
                </Dropdown>}




              </label>

              <input type="text" className="form-control" placeholder='Enter Remark...' value={remark} onChange={(e) => setRemark(e.target.value)} />
              <Badge onClick={onClickRemarkSubmit} bg="" className="badge-primary light mt-2">Submit</Badge>
            </div>}
            <div className="col-sm-6 mb-3">



            </div>

            {<div id='showImg'> <SoImageShower title={viewData} images={images} /> </div>}
          </div>
        </div>
      </Offcanvas>
    </>

  )
})

export default SoTrakingCanvas