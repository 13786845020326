import React, { useEffect, useState } from 'react'
import MainPagetitle from '../../layouts/MainPagetitle'
import ProfileTopMenu from './ProfileTopMenu'
import Select from 'react-select';
import {Link} from 'react-router-dom';
import IMAGES from '../../../images/big/key.jpg';
import { Formik } from 'formik';
// import MyImage from "../../images/iconly/Bulk/Password.svg";
import * as Yup from 'yup';
import sortedCodesList from '../../constant/countryDialCode';
import { useSelector } from 'react-redux';
import { UpdateVendorProfile } from '../../../services/Profile/Profile';
import swal from 'sweetalert';
import { getStatesByShort } from 'countrycitystatejson';

function KeyDetails({data,submited=()=>{}}) {
    const status = useSelector(state => state.auth.auth.data.status);
    const [isGst,setIsGst]=useState(false)
    const [code,setCode]=useState( data?.country_code? data?.country_code:'IN')
    const getStatesForCountry = (countryCode) => {
      const states = getStatesByShort(countryCode);
      return states;
    };
    
    // Example usage
    const countryCode = 'US'; // Country code for the United States
    const states = getStatesForCountry(code);
    console.log("OOOOOOOOOOOOOOOOORRRRRRRRR",states)
  const [formData,setFormData]=useState({
    country:{
        value: 'India',
        label: 'India',
        code:'IN'
      },
      city:'',
    state:{
      value: 'Andhra Pradesh',
      label: `Andhra Pradesh`,

    },
    area:'',
    address:'',
    organization:'',
 
    gst:'',
    pan:'',
  })

  console.log('datadata',data)
  useEffect(()=>{
    // console.log(data,'OOOOOOOoo')
    if(data){
setFormData({
    country:{
        value:data?.country? data?.country.charAt(0) + data?.country?.slice(1).toLowerCase():'India',
        label:data?.country? data?.country.charAt(0) + data?.country.slice(1).toLowerCase():'India'
      },
      city:data?.city,
    state:{value:  data?.state, label: data?.state },
    area:data?.area,
    address:data?.address,
    organization:data?.organization_name,
    gst:data?.gst,
    pan:data?.pan_no,
  }
)
setIsGst(data?.gst?true:false)
    }
  },[data&&data])
  console.log("sdksjd",formData)


  var panRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
  var gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[A-Z\d]{1}$/;
  const _validationSchema = Yup.object().shape({
    state:Yup.object().required('state is required'),
    city:Yup.string().required('city is required'),
    area:Yup.string().required('area is required'),
    organization:Yup.string().required('organization is required'),
     address:Yup.string().required('address is required'),
    gst:isGst&&Yup.string().required('gst is required').matches(gstRegex,'Enter Valid GST'),
    pan:Yup.string().required('pan is required').matches(panRegex,'Enter Valide PAN number'),
  });

  const getprofileData=async (data)=>{
    await UpdateVendorProfile(data).then((response)=>{
         if(response.status){
            // setListData(response?.data?.data?response?.data?.data:[])
            console.log('::::::::::----::',response)
            swal("Done", "Update Successfully!", "success")
         }          
            }).catch((err)=>console.log(err))
 }

const _onSubmit = async (values, resetForm) => {
  console.log("sdsd",values)
    const formData = values;
    getprofileData({
        'vendor_auto_id':data?._id ,
'organization_name': values?.organization,
'email_id':data?.email_id,
'mobile_number':data?.mobile_number,
'pan_no': values?.pan,
'coordination_person_name': data?.coordination_person_name,
'designation': data?.designation,
'country_code': data?.country_code,
'country': values?.country?.value.toUpperCase(),
'primary_category': data?.primary_category,
'salutation': data?.salutation,
'firstname': data?.firstname,
'lastname': data.lastname,
'username': data.username,
'state': values.state?.value,
'city': values?.city,
'area': values?.area,
'address': values?.address,
    })
    console.log('uduisduisdiuds',formData)
    submited('contactDetails')
}
  return (
    <>

    
          <div className="basic-form">
                
          <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <div className="clearfix">
                            <div className="card card-bx profile-card author-profile m-b30">
                                <div className="card-body">
                                    <div className="p-5">
                                   
                                    <img src={IMAGES} alt="" className="w-100" style={{borderRadius:"10px"}} />
                                   
                                    </div>
                                   
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            {/* <div className="card-header">
                                <h6 className="title">Account setup</h6>
                            </div> */}
                             <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={_validationSchema}
          onSubmit={async (values, { resetForm }) => {
            _onSubmit(values, resetForm);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,

            /* and other goodies */
          }) => (
        
                            <form className="profile-form" onSubmit={handleSubmit}>
                                {console.log('values',values)}
                                <div className="card-body">
                                    <div className="row">
                                      

                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Country*</label>

                                            <Select   className="custom-react-select" 
                                           options={sortedCodesList?.map(option => ({
                                            value: option.name,
                                            label: `${option.name}`,
                                            code:option.code
                                          }))}
                                            name='country'
                                             value={values.country}
                                           
                                            onChange={(e)=>{
                                              setFieldValue('country',e)
                                              setFieldValue('state','')
                                              setCode(e.code)
                                            }}
                                            // onBlur={handleBlur}
                                            //  defaultValue={sortedCodesList[0]}
                                                isSearchable={false}
                                                isDisabled={status&&true}
                                                
                                            />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">State*</label>
                                            <Select   className="custom-react-select" 
                                           options={states?.map(option => ({
                                            value: option,
                                            label: option
                                          }))}
                                            name='state'
                                             value={values.state}
                                            // touched={touched.country}
                                            // errors={errors.country}

                                            onChange={(e)=>{
                                              console.log("e",e)
                                              setFieldValue('state',e)
                                            }}
                                            // onBlur={handleBlur}
                                              defaultValue={states?.[0]}
                                                isSearchable={false}
                                                isDisabled={status&&true}
                                                
                                            />
                                              {touched.state && errors.state ? (
        <span className="text-danger">{errors.state}</span>
      ) : null} 
                                            {/* <input
                                             disabled={status&&true}
                                             name='state'
                                             value={values.state}
                                             touched={touched.state}
                                             errors={errors.state}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             type="text" className="form-control"placeholder='Enter state...'  />
                                               {touched.state && errors.state ? (
        <span className="text-danger">{errors.state}</span>
      ) : null} */}
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">City*</label>
                                            <input
                                            disabled={status&&true}
                                               name='city'
                                               value={values.city}
                                               touched={touched.city}
                                               errors={errors.city}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                             type="text" className="form-control"placeholder='Enter city...'  />
                                                 {touched.city && errors.city ? (
        <span className="text-danger">{errors.city}</span>
      ) : null}
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Area*</label>
                                            <input 
                                            disabled={status&&true}
                                             name='area'
                                             value={values.area}
                                             touched={touched.area}
                                             errors={errors.area}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                            type="text" className="form-control"placeholder='Enter area...'  />
                                               {touched.area && errors.area ? (
        <span className="text-danger">{errors.area}</span>
      ) : null}
                                        </div>
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">Address*</label>
                                            <textarea 
                                            disabled={status&&true}
                                             name='address'
                                             value={values.address}
                                             touched={touched.address}
                                             errors={errors.address}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                            type="text-area" className="form-txtarea form-control"placeholder='Enter Address...'  />
                                            {touched.address && errors.address ? (
        <span className="text-danger">{errors.address}</span>
      ) : null}
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Organization Name*</label>
                                            <input
                                            disabled={status&&true}
                                             name='organization'
                                             value={values.organization}
                                             touched={touched.organization}
                                             errors={errors.organization}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             type="text" className="form-control"placeholder='Organization Name...'  />
                                              {touched.organization && errors.organization ? (
        <span className="text-danger">{errors.organization}</span>
      ) : null}
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">PAN Number*</label>
                                            <input
                                            disabled={status&&true}
                                             name='pan'
                                             value={values.pan}
                                             touched={touched.pan}
                                             errors={errors.pan}
                                             onChange={(e)=>setFieldValue('pan',e.target.value.toUpperCase())}
                                             onBlur={handleBlur} 
                                             type="text" className="form-control"placeholder='PAN Number...'  />
                                                {touched.pan && errors.pan ? (
        <span className="text-danger">{errors.pan}</span>
      ) : null}
                                        </div>
<div className='col-sm-12 m-b30'>
<label className="form-label m-2">Do you have GST number?</label>
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
																			<input onChange={()=>{setIsGst(true)}} checked={isGst?true:false} type="radio" className="form-check-input" id="customRadioBox987" name="optradioCustom1" />
																			<label className="form-check-label" htmlFor="customRadioBox987">Yes</label>
																		</div>
																		<div className="form-check custom-checkbox mb-2 form-check-inline">
																			<input onChange={(value)=>{setIsGst(false)}}checked={isGst?false:true} type="radio" className="form-check-input" id="customRadioBox988" name="optradioCustom1" />
																			<label className="form-check-label" htmlFor="customRadioBox988">No</label>
																		</div>
                                    </div>
                                  {isGst&&  <div className="col-sm-6 m-b30">
                                            <label className="form-label">Enter GST*</label>
                                            <input
                                             name='gst'
                                             value={values.gst}
                                             touched={touched.gst}
                                             errors={errors.gst}
                                             onChange={handleChange}
                                             onBlur={handleBlur} 
                                             type="text" className="form-control"placeholder='GST no...'  />
                                               {touched.gst && errors.gst ? (
        <span className="text-danger">{errors.gst}</span>
      ) : null}
                                        </div>}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Submit</button>
                                 
                                </div>
                            </form>
                                 )}
                                 </Formik>
                        </div>
                    </div>
                </div>
            </div>     
          
        
     
      </div>
   </>
  )
}

export default KeyDetails