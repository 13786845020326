const BASE_URL="https://app-precismart.com/api";

const IMAGE_URL="http://35.154.217.163/new/images/RFQ"
export const LOGIN_API=`${BASE_URL}/loginVendor`;
export const GOOGLE_LOGIN_API=`${BASE_URL}/VendorGoogleLogin`;
export const REGISTER_VENDOR_API=`${BASE_URL}/vendorRegistrationsnew`;
export const CUSTOMER_LOGIN=`${BASE_URL}/customer-registration`;
export const UPDATE_PASSWORD_API=`${BASE_URL}/updatePasswordVendor`;

export const Forgot_PASSWARD=`${BASE_URL}/forgotPasswordVendor`;

export const GET_MAINCATEGORY_LIST=`${BASE_URL}/getMaincategoryList`
//customer

export const GET_CUSTOMER_COMPLETION_COUNT=`${BASE_URL}/get_customer_profile_completion_count`
export const GET_CUSTOMER_PROJECT_TRACKING_DROPDOWN=`${BASE_URL}/getCustomerPo`

export const GET_CUSTOMER_SUBASS=`${BASE_URL}/getCustomerSubass`;
export const SUBMIT_CUSTOMER_REMARK=`${BASE_URL}/submit_customer_remark_status` 
export const GET_CUSTOMER_REMARK=`${BASE_URL}/show_customer_remark_status` 

export const CREATE_PASSWORD_API=`${BASE_URL}/setvendor-password`;
export const CREATE_PASSWORD_FOR_CUSTOMER_API=`${BASE_URL}/setcustomer-password`

export const GET_VENDOR_PROFILE_COUNT_API=`${BASE_URL}/get_profile_completion_count`;
export const GET_VENDOR_DASHBORD_COUNT_API=`${BASE_URL}/get_vendor_dashboard_count`;

//RFQ
export const GET_RFQ_VENDOR_API=`${BASE_URL}/getRfqVendor`;


export const GET_AWARDED_RFQ_API=`${BASE_URL}/get_assigned_projects_vendor`;


export const GET_ACTIVE_RFQ_API=`${BASE_URL}/getRfqVendor`;

export const UPDATE_RFQ_API=`${BASE_URL}/updateRfq`;

export const AWARD_UPDATE_RFQs_API=`${BASE_URL}/rfqs-status-update`;


export const REGRATED_RFQ_API=`${BASE_URL}/get_assigned_projects_vendor`;

export const UNAWARDED_RFQ_API=`${BASE_URL}/get_assigned_projects_vendor`;

export const COMPLETED_RFQ_API=`${BASE_URL}/get-rfqs-complete`;

export const SO_TRACKING_RFQ_API=`${BASE_URL}/get-rfqs-accept`;
export const SO_TRACKING_GET_REMARK_API=`${BASE_URL}/show_vendor_remark_status`;
export const SO_TRACKING_ADD_REMARK_API=`${BASE_URL}/submit_vendor_remark_status`;

export const SO_TRACKING_STATUS_UPDATE=`${BASE_URL}/updateRfqStatus`;

//profile
export const VENDOR_PROFILE_API=`${BASE_URL}/profileVendor`;

export const VENDOR_FINICIAL_DETAIL_API=`${BASE_URL}/get_vendor_finincial_details`;

export const VENDOR_BANK_DETAIL_API=`${BASE_URL}/get_vendor_bank_details`;



export const SHOW_VENDOR_PROCESS_DATA=`${BASE_URL}/showVendorProcessData`
export const GET_ALL_PROCESS_DATA=`${BASE_URL}/getAllProcessData`

export const UPDATE_VENDOR=`${BASE_URL}/updateProfileVendor`

export const UPDATE_VENDOR_FININCIAL_DETAIL=`${BASE_URL}/add_vendor_finincial_details`

export const UPDATE_VENDOR_BANK_DETAIL=`${BASE_URL}/add_vendor_bank_details`


export const GET_SEGMENT_API=`${BASE_URL}/getSegmentCategoryList`