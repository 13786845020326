import React, { useEffect, useState } from 'react'
import MainPagetitle from '../../layouts/MainPagetitle'
import ProfileTopMenu from '../Profile/ProfileTopMenu'
import KeyDetails from '../Profile/KeyDetails';
import ContactDetails from '../Profile/ContactDetails';
import BuisnessProfile from '../Profile/BuisnessProfile';
import FinancialDetails from '../Profile/FinancialDetails';
import BankDetails from '../Profile/BankDetails';
import { GetVendorBankDetails, GetVendorFinicialDetails, GetVendorProfile } from '../../../services/Profile/Profile';
import { useSelector } from 'react-redux';


function Profile() {
    const [tab, setTab] = useState(1); // Corrected to useState
    const id = useSelector(state => state.auth.auth.data.id);
    const [profileData,setProfileData]=useState();
    const [bankData,setBankData]=useState();
    const [finicialData,setFinicialData]=useState()
const[submitedkey,setSubmitedKey]=useState('keyDetails');

    const getProfileData=async ()=>{
      await GetVendorProfile(id).then((response)=>{
        if(response.status){
          console.log('************',response.data)
           setProfileData(response?.data?.profile)
        }          
           }).catch((err)=>console.log(err))
    }

    const getBankData=async ()=>{
      await GetVendorBankDetails(id).then((response)=>{
        if(response.status){
          setBankData(response?.data?.data[0])
        }          
           }).catch((err)=>console.log(err))
    }

    const getFinicialData=async ()=>{
      await GetVendorFinicialDetails(id).then((response)=>{
        if(response.status){
          setFinicialData(response?.data?.data[0])
        }          
           }).catch((err)=>console.log(err))
    }

    console.log('::::::::::::DDDDDDDDDDDD',profileData,bankData,finicialData)

    const submited=(e)=>{
      setSubmitedKey(e)
      getProfileData();
      getBankData();
      getFinicialData();
    }

    useEffect(()=>{
      getProfileData();
      getBankData();
      getFinicialData();
    },[submitedkey])

   
  return (
    <>
      <MainPagetitle mainTitle="Profile" pageTitle={'Profile'} parentTitle={'My Profile'} />  
            <div className="container-fluid">
				<div className="row">
                <ProfileTopMenu tabName={submitedkey} tabNumber={(tabNo)=>{setTab(tabNo)}}/>
                <div className="row">
          <div >
            {tab==1 &&<KeyDetails data={profileData} submited={(e)=>submited(e)}/>}
            {tab==2 &&<ContactDetails data={profileData} submited={(e)=>submited(e)}/>}
            {tab==3 &&<BuisnessProfile data={profileData} submited={(e)=>submited(e)}/> }
            {tab==4 &&<FinancialDetails data={finicialData} submited={(e)=>submited(e)}/>}
            {tab==5 &&<BankDetails data={bankData} submited={(e)=>submited(e)}/>}
          </div>
        </div>
                </div>
                </div>
                
    </>
  )
}

export default Profile