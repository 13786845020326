import React, { useEffect, useRef, useState } from 'react'
import PageTitle from "../../../layouts/PageTitle";

import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Dropdown,
    ProgressBar,
  } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetRegratedRfqList } from '../../../../services/RFQ/Rfq';
import RegrettedRfqCanvas from '../../RegrettedRfq/RegrettedRfqCanvas';
import Select from 'react-select';
function RegrettedRfq() {

  const id = useSelector(state => state.auth.auth.data.id);
  const [ListData,setListData]=useState([]);
  const [siderData,setSiderData]=useState({})

  const [searchBoxData,setSearchBox]=useState([]);
  const [filterDatas,setFilterDatas]=useState([]);

  const [newfilterData,setNewFilterData]=useState([])

 useEffect(()=>{
  setFilterDatas(ListData)
  if(ListData?.length>0){
    mergedProject()
    }
 },[ListData])

 useEffect(()=>{
  if(ListData?.length>0){
    mergedProject()
    }
 },[filterDatas])

  const filterData=(e)=>{
    setFilterDatas(ListData?.filter(item=>item.po_no==e))
  }
  console.log(ListData)
   const getprofileData=async ()=>{
     await GetRegratedRfqList(id).then((response)=>{
          if(response.status){
            //  setListData(response?.data?.data)
            let list=response?.data?.data?.filter((e)=>e?.vendor_rfq_status=="Reject")
             setListData(list)
             setSearchBox(list?.filter((e)=>e?.status=="Cancelled")?.map((item)=>item?.po_no))
          }          
             }).catch((err)=>console.log(err))
  }
  useEffect(()=>{
   getprofileData()
  },[])
  const updateSuccessfuly=()=>{
    getprofileData()
   }
  const task = useRef();

  const mergedProject=()=>{
    const map={};
    console.log(filterDatas,"u______________")
    filterDatas?.forEach(item=>{
      const key=item.project_id;
      if(!map[key]){
        map[key]=[];
      }
      map[key].push(item);
    })
  
  const mergedData=[];
  for(let key in map){
   
    if(map[key]?.length>1){
     
      const firstItem=map[key][0];
  
      const mergedItem={...firstItem};
      mergedItem.rowSpan=map[key]?.length;
      console.log(mergedItem,"lllll")
  
      mergedData.push(mergedItem);
      for(let i=1;i<map[key]?.length;i++){
        mergedData.push(map[key][i]);
      }
    }else{
      mergedData.push(...map[key])
    }
  }

  setNewFilterData(mergedData)
  }
  const setObjectsForEdit=(items)=>{
    const aa=filterDatas.filter((item)=> item.rfq_no===items)
    console.log(aa,"jjjjj")
      setSiderData(aa)
    }
  return (
    <>
    <PageTitle activeMenu="Regretted RFQ" motherMenu="Dashboard" />

 <div className="row m-2">
           <div className="col-xl-12 wid-100">
    <Col lg={12}>
    <Link to={'/new-dashbord'}>
    <Badge className='mb-2' bg="primary">{`Back to Dashboard`}</Badge>
    </Link>
         <Card>
       
           <Card.Header>
             <Card.Title>Regretted RFQ</Card.Title>
           </Card.Header>
           <div className='col-3'>
             <Select   className="custom-react-select m-2" 
                                           options={Array.from(new Set(searchBoxData)).map(option => ({
                                            value: option,
                                            label: option
                                        }))}
                                            name='country'
                                            onChange={(e)=>filterData(e.value)}
                                                isSearchable={false}                                             
                                            />
             </div>
           <Card.Body>
             <Table responsive striped>
               <thead>
                 <tr>
                   <th>#</th>
                   <th>RFQ No.</th>
                   <th>Sub Assembly</th>
                   <th>Description</th>
                   <th>Quoted Price</th>
                 </tr>
               </thead>
               <tbody>

               {newfilterData?.length&& newfilterData.map((item,index)=>  <tr>
                      <th> {index+1}</th>
                      {index===0||(index>0&& item.rfq_no!==newfilterData[index-1].rfq_no)? <td rowSpan={item?.rowSpan||1} style={{color:'blue'}} onClick={()=>{
                        task.current.showEmployeModal()
                        setObjectsForEdit(item.rfq_no)
                        }}>{item?.rfq_no}</td>:null}
                      <td>{item?.sub_assembly_name}</td>
                      <td>{item?.rfq_description}</td>
                      <td>{item?.quoted_price}                   
                      </td>
                      {/* <td>January 22</td>
                      <td className="color-primary">$21.56</td> */}
                    </tr>
                 )
                  }
               </tbody>
             </Table>
           </Card.Body>
         </Card>
       </Col>
       </div>
       </div>
       <RegrettedRfqCanvas
           ref={task}
           updateSuccessfuly={updateSuccessfuly}
                data={siderData}
                ></RegrettedRfqCanvas>
</>
  )
}

export default RegrettedRfq