import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { getSegmentApi } from '../../../services/Profile/Profile';








 const CustomClearText = () => "clear all";
 const ClearIndicator = (props) => {
    const {
       children = <CustomClearText />,
       getStyles,
       innerProps: { ref, ...restInnerProps },
    } = props;
    return (
       <div
          {...restInnerProps}
          ref={ref}
          style={getStyles("clearIndicator", props)}
       >
          <div style={{ padding: "0px 5px" }}>{children}</div>
       </div>
    );
 };
 
 const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: "pointer",
    color: state.isFocused ? "blue" : "black",
 });
export default function BusinessDropDown({optionss,defaultValue,onchange=()=>{}}) {
    const [option,setOption]=useState([]);
    console.log('$$$$$$$$$$$$$$$$$$',optionss)
 

useEffect(()=>{
    let outputArray = optionss.map(item => ({
        value: item.category_name,
        label: item.category_name,
        color: item._id
    }));
    setOption(outputArray)
},[optionss])
    return (
       <Select
       required
       defaultValue={defaultValue}
          closeMenuOnSelect={false}
          components={{ ClearIndicator }}
          styles={{ clearIndicator: ClearIndicatorStyles }}
          // defaultValue={[colourOptions[4], colourOptions[5]]}
          isMulti
          options={option}
          onChange={onchange}
       />
    );
 }