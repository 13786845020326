import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import SoImageShower from '../SoTraking/SoImageShower'
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
import { Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ProjectRejectionCanvas=forwardRef((props, ref) => {
    const onInit = () => {    
    };
    const [addEmploye , setAddEmploye] = useState(false);
    const [title,setTitle]=useState('');
    const [imageArray,setImageArray]=useState([])
    useEffect(()=>{
setImageArray(props.data)
setTitle(props.title)
    },[props])
    useImperativeHandle(ref, () => ({
        showEmployeModal() {
            setAddEmploye(true)
        }    
    }));
    const nav = useNavigate();
    const handleSubmit=(e)=>{
        e.preventDefault();
        nav("#");
    }


  return (
    <>
     <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
        <div className="offcanvas-header">
            <h5 className="modal-title" id="#gridSystemModal">{'Project Tracking'}</h5>
            <button type="button" className="btn-close" 
                onClick={()=>setAddEmploye(false)}
            >
                <i className="fa-solid fa-xmark"></i>
            </button>
        </div>
       
        <div className="offcanvas-body">
        <div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{title}</h4>
                                {/* <h6 style={{color:'orange'}}>{images[0]?.date}</h6> */}
							</div>



        {imageArray&& imageArray.length? 
        		<div className="card-body pb-1">
                <LightGallery
                    onInit={onInit}
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    elementClassNames="row"
                >
                    {imageArray.map((item,index)=>(
                        <div data-src={`https://app-precismart.com/images/project_track/${item}`} className="col-lg-3 col-md-6 mb-4" key={index}>
                            <img src={`https://app-precismart.com/images/project_track/${item}`} style={{width:"100%"}} alt="gallery" className='cursor-pointer'/>
                            
                           
                        </div>
                    ))}
                </LightGallery>					
                    
            </div>
            :
            <div className="card-body pb-1">
                NO IMAGE Found
                </div>
        }
        </div>
   
</div>
</div>
</div>
     </Offcanvas>
     
    </>
  )
})

export default ProjectRejectionCanvas