import React from 'react'
import PageTitle from '../../layouts/PageTitle';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
// import lgZoom from 'lightgallery/plugins/zoom';

function SoImageShower({title,images}) {
    const onInit = () => {    
    };

    console.log('LLLLL',images)
	
const lightGallery = [
	{ large : 'https://buffer.com/library/content/images/2023/10/free-images.jpg', thumb :	'https://buffer.com/library/content/images/2023/10/free-images.jpg',	},	
    { large : 'https://buffer.com/library/content/images/2023/10/free-images.jpg', thumb :	'https://buffer.com/library/content/images/2023/10/free-images.jpg',	},	
    { large : 'https://buffer.com/library/content/images/2023/10/free-images.jpg', thumb :	'https://buffer.com/library/content/images/2023/10/free-images.jpg',	},	
    { large : 'https://buffer.com/library/content/images/2023/10/free-images.jpg', thumb :	'https://buffer.com/library/content/images/2023/10/free-images.jpg',	},	
    { large : 'https://buffer.com/library/content/images/2023/10/free-images.jpg', thumb :	'https://buffer.com/library/content/images/2023/10/free-images.jpg',	},	

];
	return(
		<>	
	
	{	title&&	<div className='container-fluid'>
				<div className="row">
					<div className="col-lg-12" style={{height:'50%'}}>
						{images && images?.length&&
						images.map((item)=>
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{title}</h4>
                                <h6 style={{color:'orange'}}>{item?.date}</h6>
							</div>
							
							<div className="card-body pb-1">
								<LightGallery
									onInit={onInit}
									speed={500}
									plugins={[lgThumbnail, lgZoom]}
									elementClassNames="row"
								>
									{item?.project_tracking_details&& item?.project_tracking_details.map((item,index)=>(
										<div data-src={`https://app-precismart.com/images/project_track/${item.process_image}`} className="col-lg-3 col-md-6 mb-4" key={index}>
											<img src={`https://app-precismart.com/images/project_track/${item.process_image}`} style={{width:"100%"}} alt="gallery" className='cursor-pointer'/>
                                            <div className='ss'>
                                            <p>Remark : <span style={{color:'black'}}>{item.remark}</span></p>
                                            <p>Completion Percent: <span style={{color:'black'}}>{item.completion_percentage}%</span></p>
                                            <p>User Name:<span style={{color:'black'}}>{item.uploader_name}</span></p></div>

										</div>
									))}
								</LightGallery>					
									
							</div>
						</div>)}
						{/* <!-- /# card --> */}
					</div>
				</div>
			</div>	}	
		</>
	)
	
}

export default SoImageShower