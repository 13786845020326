import React, { useEffect, useRef, useState } from 'react'
import PageTitle from "../../../layouts/PageTitle";

import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Dropdown,
    ProgressBar,
  } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { GetSoTrakingList } from '../../../../services/RFQ/Rfq';
import { useSelector } from 'react-redux';
import SoTrakingCanvas from '../../SoTraking/SoTrakingCanvas';
import Select from 'react-select';
import './style.css'
function SoTraking() {
  const id = useSelector(state => state.auth.auth.data.id);
  const [ListData,setListData]=useState([]);
  const [siderData,setSiderData]=useState({})

  const [searchBoxData,setSearchBox]=useState([]);
  const [filterDatas,setFilterDatas]=useState([]);

  const [newfilterData,setNewFilterData]=useState([])
console.log('newfilterDatanewfilterData',newfilterData)
 useEffect(()=>{
  setFilterDatas(ListData)
  if(ListData?.length>0){
    mergedProject()
    }
 },[ListData])


  const filterData=(e)=>{
    setFilterDatas(ListData?.filter(item=>item.po_no==e))
  }


   const getprofileData=async ()=>{
     await GetSoTrakingList(id).then((response)=>{
          if(response.status){
             setListData(response?.data?.data?response?.data?.data:[])
             setSearchBox(response?.data.data.map((item)=>item.po_no))
          }          
             }).catch((err)=>console.log(err))
  }
  useEffect(()=>{
   getprofileData()
  },[])
  const updateSuccessfuly=()=>{
    getprofileData()
   }

   useEffect(()=>{
    if(ListData?.length>0){
      mergedProject()
      }
   },[filterDatas])

  const task = useRef();


  const mergedProject=()=>{
    const map={};
    console.log(filterDatas,"u______________")
    filterDatas?.forEach(item=>{
      const key=item.so_no;
      if(!map[key]){
        map[key]=[];
      }
      map[key].push(item);
    })
  
  const mergedData=[];
  for(let key in map){
   
    if(map[key].length>1){
     
      const firstItem=map[key][0];
  
      const mergedItem={...firstItem};
      mergedItem.rowSpan=map[key].length;
      console.log(mergedItem,"lllll")
  
      mergedData.push(mergedItem);
      for(let i=1;i<map[key].length;i++){
        mergedData.push(map[key][i]);
      }
    }else{
      mergedData.push(...map[key])
    }
  }
  console.log('&&&&&&&&&&&&&&',mergedData)
  setNewFilterData(mergedData)
  }
  return (
    <>
    <PageTitle activeMenu="SO Tracking" motherMenu="Dashboard" />

 <div className="row m-2">
           <div className="col-xl-12 wid-100">
    <Col lg={12}>
    <Link to={'/new-dashbord'}>
    <Badge className='mb-2' bg="primary">{`Back to Dashboard`}</Badge>
    </Link>
         <Card>
       
           <Card.Header>
             <Card.Title>SO Tracking</Card.Title>
           </Card.Header>
           <div className='col-3'>
             <Select   className="custom-react-select m-2" 
                                           options={Array.from(new Set(searchBoxData)).map(option => ({
                                            value: option,
                                            label: option
                                        }))}
                                            name='country'
                                            onChange={(e)=>filterData(e.value)}
                                                isSearchable={false}                                             
                                            />
             </div>
           <Card.Body className='ActiveTable'>
             <Table responsive striped>
               <thead>
                 <tr>
                   <th>#</th>
                   <th>SO No.</th>
                   <th>Sub Assembly</th>
                   <th>Description</th>
                   <th>RFQ Completion Date</th>
                 </tr>
               </thead>
               <tbody>
               {newfilterData?.length&& newfilterData.map((item,index)=>  <tr>
                      <th> {index+1}</th>
                      {index===0||(index>0&& item.so_no!==newfilterData[index-1].so_no) ? 
                       (<td rowSpan={item?.rowSpan||1} >{item?.so_no}</td>):null}
                      <td style={{color:'blue'}} onClick={()=>{
                        task.current.showEmployeModal()
                        setSiderData(item)
                        }}>{item?.sub_assembly_name}</td>
                      <td>{item?.rfq_description}</td>
                      <td>{item?.completion_date}                   
                      </td>
                      {/* <td>January 22</td>
                      <td className="color-primary">$21.56</td> */}
                    </tr>
                 )
                  }
               </tbody>
             </Table>
           </Card.Body>
         </Card>
       </Col>
       </div>
       </div>
       <SoTrakingCanvas
       completeButton={true}
           ref={task}
           updateSuccessfuly={updateSuccessfuly}
                data={siderData}
                ></SoTrakingCanvas>
</>
  )
}

export default SoTraking