
import { useSelector } from "react-redux";
import { SVGICON } from "../../constant/theme";
const localData=JSON.parse(localStorage.getItem('userDetails'))
console.log('localDatalocalData',localData)

 const SideMenu=()=>{
    const status = useSelector(state => state.auth.auth.data.status);
    console.log('FFFFFFFFFFFFFFFFF_______________',status)
    if(status){
        return [
            //Content
            {
                title: '',
                classsChange: 'menu-title'
            },
            //Dashboard
            // {
            //     title: 'Dashboard',	
            //     classsChange: 'mm-collapse',		
            //     iconStyle: SVGICON.Home,
            //     content: [
                    
            //         // {
            //         //     title: 'Dashboard Light',
            //         //     to: '/dashboard',					
            //         // },
            //         {
            //             title: 'Dashboard',
            //             to: '/dashboard-dark',
                        
            //         },            
            //     ],
            // },
            {   
                title:'Dashboard',
                iconStyle:  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.125 6.375L8.5 1.41667L14.875 6.375V14.1667C14.875 14.5424 14.7257 14.9027 14.4601 15.1684C14.1944 15.4341 13.8341 15.5833 13.4583 15.5833H3.54167C3.16594 15.5833 2.80561 15.4341 2.53993 15.1684C2.27426 14.9027 2.125 14.5424 2.125 14.1667V6.375Z" stroke="#2C2C2C" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.375 15.5833V8.5H10.625V15.5833" stroke="#2C2C2C" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>,
                 to: '/dashboard-dark',
              
            },
            {   
                title:'My Profile',
                classsChange: 'mm-collapse',
                iconStyle: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z" stroke="#130F26" strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                // to: '/employee',
                content: [
                    {
                        title: 'Profile',
                        to: '/profile',
                    },
                ]
            },
        
            {   
                title:'Change Password',
                iconStyle: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.334 2.75012H7.665C4.644 2.75012 2.75 4.88912 2.75 7.91612V16.0841C2.75 19.1111 4.635 21.2501 7.665 21.2501H16.333C19.364 21.2501 21.25 19.1111 21.25 16.0841V7.91612C21.25 4.88912 19.364 2.75012 16.334 2.75012Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.6889 12.0001C10.6889 13.0231 9.85986 13.8521 8.83686 13.8521C7.81386 13.8521 6.98486 13.0231 6.98486 12.0001C6.98486 10.9771 7.81386 10.1481 8.83686 10.1481H8.83986C9.86086 10.1491 10.6889 10.9781 10.6889 12.0001Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.6919 12.0001H17.0099V13.8521" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.1816 13.8522V12.0002" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>,
                 to: '/change-password',
              
            },
            {   
                title:'Help',
                iconStyle: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                 to: '/help',
              
            },
            // {   
            //     title:'Core HR',
            //     iconStyle: SVGICON.CoreHr,
            //     to: '/core-hr',
            // },
            // {   
            //     title:'Finance',
            //     iconStyle: SVGICON.Finance,
            //     to: '/finance',
            // },
            // //Tasks
            // {
            //     title: 'Tasks',
            //     classsChange: 'mm-collapse',
            //     iconStyle: SVGICON.Task,
            //     content: [
            //         {
            //             title: 'Tasks',
            //             to: 'task',
            //         },
            //         {
            //             title: 'Task Summary',
            //             to: 'task-summary',
            //         },
                    
            //     ]
            // },
            // {   
            //     title:'Performance',
            //     iconStyle: SVGICON.Performance,
            //     to: '/performance',
            // },
            // {   
            //     title:'Projects',
            //     iconStyle: SVGICON.ProjectsSidbar,
            //     to: '/project',
            // },
            
            // {   
            //     title:'Reports',
            //     iconStyle: SVGICON.Reports,
            //     to: '/reports',
            // },
            
            // {   
            //     title:'Manage Clients',
            //     iconStyle: SVGICON.ManageClient,
            //     to: '/manage-client',
            // },
            // {   
            //     title:'Blog',
            //     // update:"New",
            //     iconStyle: SVGICON.Blog,
            //     to: '/blog-1',
            // },
            // {   
            //     title:'SVG Icons',   
            //     update:"New",   
            //     iconStyle: SVGICON.SvgPage,
            //     to: '/svg-icon',
            // },
            // {
            //     title: 'OUR FEATURES',
            //     classsChange: 'menu-title'
            // },
            // //Apps
            // {
            //     title: 'Apps',	
            //     classsChange: 'mm-collapse',
            //     // update:"New",
            //     iconStyle:SVGICON.Apps,
            //     content: [
            //         {
            //             title: "Users Manager",
            //             hasMenu : true,
            //             // update:'New',
            //             content: [
            //                 {
            //                     title:"User",
            //                     to:'/user',
            //                 },
            //                 {
            //                     title:'Add User',
            //                     to:'/edit-profile'
            //                 },
            //                 {
            //                     title:'Roles Listing',
            //                     to:'/user-roles',
            //                 },
            //                 {
            //                     title:'Add Roles',
            //                     to:'/add-role'
            //                 },
            //                 {
            //                     title: 'Profile 1',
            //                     to: '/app-profile'
            //                 },
            //                 {
            //                     title: 'Profile 2',
            //                     to: '/app-profile-2'
            //                 },
            //                 {
            //                     title: 'Edit Profile',
            //                     to: 'edit-profile'
            //                 },
            //                 {
            //                     title: 'Post Details',
            //                     to: 'post-details'
            //                 },
            //             ],
            //         },
            //         {
            //             title:'Customer Manager',
            //             hasMenu : true,
            //             // update:'New',
            //             content : [
            //                 {
            //                     title:'Customer',
            //                     to:'/customer'
            //                 },
            //                 {
            //                     title:'Customer Profile',
            //                     to:'/customer-profile'
            //                 },
            //             ],
            //         },
            //         {
            //             title:'Contacts',
            //             to: '/contacts',
            //             // update:"New"
            //         },
            //         {
            //             title: 'Email',
            //             hasMenu : true,
            //             content: [
            //                 {
            //                     title: 'Compose',
            //                     to: 'email-compose',
            //                 },
            //                 {
            //                     title: 'Index',
            //                     to: 'email-inbox',
            //                 },
            //                 {
            //                     title: 'Read',
            //                     to: 'email-read',
            //                 }
            //             ],
            //         },
            //         {
            //             title:'Calendar',
            //             to: 'app-calender'
            //         },
                   
                    
            //         {
            //             title: 'Shop',                
            //             hasMenu : true,
            //             content: [
            //                 {
            //                     title: 'Product Grid',
            //                     to: 'ecom-product-grid',
            //                 },
            //                 {
            //                     title: 'Product List',
            //                     to: 'ecom-product-list',
            //                 },
            //                 {
            //                     title: 'Product Details',
            //                     to: 'ecom-product-detail',
            //                 },
            //                 {
            //                     title: 'Order',
            //                     to: 'ecom-product-order',
            //                 },
            //                 {
            //                     title: 'Checkout',
            //                     to: 'ecom-checkout',
            //                 },
            //                 {
            //                     title: 'Invoice',
            //                     to: 'ecom-invoice',
            //                 },
            //                 {
            //                     title: 'Customers',
            //                     to: 'ecom-customers',
            //                 },
            //             ],
            //         },
            //     ],
            // },
        
            // //AiKit Pages
            // {
            //     title:'AIKit',
            //     classsChange: 'mm-collapse',
            //     update:"NEW",    
            //     iconStyle:  SVGICON.AikitSvg,
            //     content : [
            //         {
            //             title:"Auto Writer",
            //             to:'auto-write'
            //         },
            //         {
            //             title:'Scheduler',
            //             to:'scheduled'
            //         },
            //         {
            //             title:'Repurpose',
            //             to:'repurpose'
            //         },
            //         {
            //             title:'RSS',
            //             to:'rss'
            //         },
            //         {
            //             title:'Chatbot',
            //             to:'chatbot'
            //         },
            //         {
            //             title:'Fine-tune Models',
            //             to:'fine-tune-models'
            //         },
            //         {
            //             title:'AI Menu Prompts',
            //             to:'prompt'
            //         },
            //         {
            //             title:'Settings',
            //             to:'setting'
            //         },
            //         {
            //             title:'Export/Import Settings',
            //             to:'import'
            //         },
            //     ],
            // },
            // //CMS
            // {
            //     title : "CMS",
            //     classsChange: 'mm-collapse',
            //     update:"NEW",
            //     iconStyle:  SVGICON.SettingMenu,
            //     content:[
            //         {
            //             title:'Content',
            //             to:'content'
            //         },            
            //         {
            //             title:'Menus',
            //             to:'menu'
            //         },
            //         {
            //             title:'Email Template',
            //             to:'email-template'
            //         },
            //         {
            //             title:'Blog',
            //             to:'blog'
            //         },
            //         {
            //             title:'Add Content',
            //             to:'add-content'
            //         },
            //         {
            //             title:'Add Email',
            //             to:'add-email'
            //         },
            //         {
            //             title:'Add Blog',
            //             to:'add-blog'
            //         },
            //         {
            //             title:'Blog Category',
            //             to:'blog-category'
            //         },
            //     ],
            // },
            // //Charts
            // {
            //     title: 'Charts',	
            //     classsChange: 'mm-collapse',
            //     iconStyle: SVGICON.Charts,
            //     content: [
                    
            //         {
            //             title: 'RechartJs',
            //             to: 'chart-rechart',					
            //         },
            //         {
            //             title: 'Chartjs',
            //             to: 'chart-chartjs',					
            //         },
            //         {
            //             title: 'Sparkline',
            //             to: 'chart-sparkline',					
            //         },
            //         {
            //             title: 'Apexchart',
            //             to: 'chart-apexchart',					
            //         },
            //     ]
            // },
            // //Boosttrap
            // {
            //     title: 'Bootstrap',	
            //     classsChange: 'mm-collapse',
            //     iconStyle: SVGICON.Bootstrap,	
            //     content: [
            //         {
            //             title: 'Accordion',
            //             to: 'ui-accordion',					
            //         },
            //         {
            //             title: 'Alert',
            //             to: 'ui-alert',					
            //         },
            //         {
            //             title: 'Badge',
            //             to: 'ui-badge',					
            //         },
            //         {
            //             title: 'Button',
            //             to: 'ui-button',					
            //         },
            //         {
            //             title: 'Modal',
            //             to: 'ui-modal',					
            //         },
            //         {
            //             title: 'Button Group',
            //             to: 'ui-button-group',					
            //         },
            //         {
            //             title: 'List Group',
            //             to: 'ui-list-group',					
            //         },
            //         {
            //             title: 'Cards',
            //             to: 'ui-card',					
            //         },
            //         {
            //             title: 'Carousel',
            //             to: 'ui-carousel',					
            //         },
            //         {
            //             title: 'Dropdown',
            //             to: 'ui-dropdown',					
            //         },
            //         {
            //             title: 'Popover',
            //             to: 'ui-popover',					
            //         },
            //         {
            //             title: 'Progressbar',
            //             to: 'ui-progressbar',					
            //         },
            //         {
            //             title: 'Tab',
            //             to: 'ui-tab',					
            //         },
            //         {
            //             title: 'Typography',
            //             to: 'ui-typography',					
            //         },
            //         {
            //             title: 'Pagination',
            //             to: 'ui-pagination',					
            //         },
            //         {
            //             title: 'Grid',
            //             to: 'ui-grid',					
            //         },
            //     ]
            // },
            // //plugins
            // {
            //     title:'Plugins',
            //     classsChange: 'mm-collapse',
            //     iconStyle : SVGICON.Plugins,
            //     content : [
            //         {
            //             title:'Select 2',
            //             to: 'uc-select2',
            //         },
            //         // {
            //         //     title:'Noui Slider',
            //         //     to: 'uc-noui-slider',
            //         // },
            //         {
            //             title:'Sweet Alert',
            //             to: 'uc-sweetalert',
            //         },
            //         {
            //             title:'Toastr',
            //             to: 'uc-toastr',
            //         },
            //         {
            //             title:'Jqv Map',
            //             to: 'map-jqvmap',
            //         },
            //         {
            //             title:'Light Gallery',
            //             to: 'uc-lightgallery',
            //         },
            //     ]
            // },
            // //Widget
            // {   
            //     title:'Widget',
            //     //classsChange: 'mm-collapse',
            //     iconStyle: SVGICON.Widget,
            //     to: 'widget-basic',
            // },
            // //Forms
            // {
            //     title:'Forms',
            //     classsChange: 'mm-collapse',
            //     iconStyle: SVGICON.Forms,
            //     content : [
            //         {
            //             title:'Form Elements',
            //             to: 'form-element',
            //         },
            //         {
            //             title:'Wizard',
            //             to: 'form-wizard',
            //         },
            //         {
            //             title:'CkEditor',
            //             to: 'form-ckeditor',
            //         },
            //         {
            //             title:'Pickers',
            //             to: 'form-pickers',
            //         },
            //         {
            //             title:'Form Validate',
            //             to: 'form-validation',
            //         },
        
            //     ]
            // },
            // //Table
            // {
            //     title:'Table',
            //     classsChange: 'mm-collapse',
            //     iconStyle:SVGICON.Table,
            //     content : [
            //         {
            //             title:'Table Filtering',
            //             to: 'table-filtering',
            //         },
            //         {
            //             title:'Table Sorting',
            //             to: 'table-sorting',
            //         },
            //         {
            //             title:'Bootstrap',
            //             to: 'table-bootstrap-basic',
            //         },
                   
        
            //     ]
            // },
            // //Pages
            // {
            //     title:'Pages',
            //     classsChange: 'mm-collapse',
            //     iconStyle: SVGICON.Pages,
            //     content : [
            //         {
            //             title:'Error',
            //             hasMenu : true,
            //             content : [
            //                 {
            //                     title: 'Error 400',
            //                     to : 'page-error-400',
            //                 },
            //                 {
            //                     title: 'Error 403',
            //                     to : 'page-error-403',
            //                 },
            //                 {
            //                     title: 'Error 404',
            //                     to : 'page-error-404',
            //                 },
            //                 {
            //                     title: 'Error 500',
            //                     to : 'page-error-500',
            //                 },
            //                 {
            //                     title: 'Error 503',
            //                     to : 'page-error-503',
            //                 },
            //             ],
            //         },
            //         {
            //             title:'Lock Screen',
            //             to: 'page-lock-screen',
            //         },
        
            //     ]
            // },
            
        ]
    }else{
return [ {   
    title:'My Profile',
    classsChange: 'mm-collapse',
    iconStyle: SVGICON.Employe,
    // to: '/employee',
    content: [
        {
            title: 'Profile',
            to: '/profile',
        },
    ]
},]
    }
}
export default SideMenu;
