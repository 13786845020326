import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// image
import logo from "../../images/logo/logo-full.png";
import { ForgoutPassword } from "../../services/AuthService";
import swal from "sweetalert";
const ForgotPassword = ({ history }) => {
  const navigate = useNavigate();
  let errorsObj = { email: ''};
  const [errors, setErrors] = useState(errorsObj);
  
const [email,setEmail]=useState('')
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
		const errorObj = { ...errorsObj };
const emailRegax=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (!emailRegax.test(email)) {
			errorObj.email = 'please write correct email';
			error = true;
		}


    setErrors(errorObj);
		if (error) {
			return;
		}
    //history.push("/dashboard");
  
      await ForgoutPassword(email)
      .then((response) => { 
  console.log('Please login using new password shared on your registered email id')
  swal('Please login using new password shared on your registered email id');

      }).catch((error) => {
        swal('Somthing Wrong..');
          console.log('LLLLLLLLLLllll')
          // const errorMessage = formatError('somthing Wrong..');
          // dispatch(loginFailedAction(errorMessage));
      });
  
  
    navigate("/login");
  };
  console.log('errorserrors',errors)
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/dashboard">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group m-2">
                        <label className="">
                          <strong>Email</strong>
                        </label>
                        <input
                         required
                          type="email"
                          className="form-control"
                          placeholder="hello@example.com"
                          onChange={(e)=>setEmail(e.target.value)}
                        />
                       {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
